
import { Col, Image, Row, Table, Tag } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useLocation } from 'react-router-dom';
import { getStatusApproval } from '../../Utils/approval';


function SettlementDetail() {
    const token = useSelector((state) => state.user.token);
    const location = useLocation();
    const [data, setData] = useState(null);
    const [benefit, setBenefit] = useState(null);
    const [approval, setApproval] = useState([]);
    const [costTransportation, setCostTransportation] = useState([]);
    const [activityImage, setActivityImage] = useState([]);
    const [report, setReport] = useState([]);
    const [totalCost, setTotalCost] = useState(0);

    const [loading, setLoading] = useState(false);

    // //Parameter
    const titlePage = 'Data Pengajuan Perjalanan Dinas';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                return record.users.name;
            }
        },
        {
            title: 'Jabatan',
            dataIndex: 'position',
            key: "position",
            render: (text, record) => {
                return record.users.position.name;
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'division',
            key: "division",
            render: (text, record) => {
                return record.users.division.name;
            }
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                var statusColor;
                if (record.status_approval.status_approval_id === 1) {
                    statusColor = 'blue';
                } else if (record.status_approval.status_approval_id === 2) {
                    statusColor = 'green';
                } else {
                    statusColor = 'red';
                }
                return (<Tag color={statusColor}>{record.status_approval.status}</Tag>);
            }
        },
    ];

    const reportColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Kota',
            dataIndex: 'city',
            key: "city",
        },
        {
            title: 'PIC',
            dataIndex: 'pic',
            key: "pic",
        },
        {
            title: 'Alamat',
            dataIndex: 'address',
            key: "address",
        },
        {
            title: 'Tipe Toko',
            dataIndex: 'store_type',
            key: "store_type",
        },
        {
            title: 'Trafik Konsumen',
            dataIndex: 'consumer_traffic',
            key: "consumer_traffic",
        },
        {
            title: 'Latar Belakang Toko',
            dataIndex: 'store_background',
            key: "store_background",
        },
        {
            title: 'Potensi',
            dataIndex: 'potency',
            key: "potency",
            render: (text, record) => {
                return currencyFormat(record.potency);
            }
        },
        {
            title: 'Realisasi Target',
            dataIndex: 'target_realization',
            key: "target_realization",
        },
        {
            title: 'Update Visitasi',
            dataIndex: 'visitation_update',
            key: "visitation_update",
        },
    ];

    const activityColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Bukti Foto',
            dataIndex: 'image',
            key: "image",
            render: (text, record) => {
                if (record.image !== undefined && record.image !== null && record.iamge !== "") {
                    return <Image width={50} height={50} src={record.image} />;
                } else {
                    return <p>tidak ada gambar</p>
                }
            }
        },
        {
            title: 'Nama Toko',
            dataIndex: 'store_name',
            key: "store_name",
        },
        {
            title: 'Kota',
            dataIndex: 'city',
            key: "city",
        },
    ];

    const CostColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Bukti Foto',
            dataIndex: 'image',
            key: "image",
            render: (text, record) => {
                if (record.image !== undefined && record.image !== null && record.iamge !== "") {
                    return <Image width={50} height={50} src={record.image} />;
                } else {
                    return <p>tidak ada gambar</p>
                }
            }
        },
        {
            title: 'Nama Biaya',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Biaya',
            dataIndex: 'cost',
            key: "cost",
            render: (text, record) => {
                return currencyFormat(record.cost);
            }
        },
    ];

    useEffect(() => {
        if (location.state != null) {
            getApi("GET", '/settlement/' + location.state.item.settlement_id, 'request');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'request':
                        getApi("GET", '/benefitlevel?leveldetail=' + res.data.user.level_detail_id, 'benefit');
                        setData(res.data);
                        setApproval(res.data.approval);
                        setCostTransportation(res.data.cost_transportation);
                        setActivityImage(res.data.activity_image);
                        setReport(res.data.report_submission);
                        var costs = res.data.cost_transportation;
                        var total_cost = 0;
                        for (let i = 0; i < costs.length; i++) {
                            total_cost += Number(costs[i].cost)
                        }
                        setTotalCost(total_cost);
                        setLoading(false);
                        break;
                    case 'benefit':
                        setBenefit(res.data.data[0]);
                        setLoading(false);
                        break;
                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }


    const checkStatus = () => {
        return (<Tag color={getStatusApproval(data).color}>{getStatusApproval(data).value}</Tag>);
    }

    if (data != null && benefit != null)
        return (
            <div>
                <Helmet>
                    <title>{titlePage}</title>
                </Helmet>
                <h1>{data.budget_submission_code}</h1>
                <h3>Judul : {data.title}</h3>
                {checkStatus()}

                <h4>Yang Mengajukan</h4>
                <Row>
                    <Col>
                        <div>Nama </div>
                        <div>Posisi </div>
                        <div>Divisi </div>
                    </Col>
                    <Col>
                        <div>: {data.user.name}</div>
                        <div>: {data.user.position.name}</div>
                        <div>: {data.user.division.name}</div>
                    </Col>
                </Row>
                <hr />
                <h3>Detail</h3>
                <Row>
                    <Col span={12}>
                        <h4>Informasi</h4>
                        <Row>
                            <Col>
                                <div>Deskripsi </div>
                                <div>Total Biaya </div>
                            </Col>
                            <Col>
                                <div>: {data.desc}</div>
                                <div>: <b>{currencyFormat(totalCost)}</b></div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <h4>Approval By</h4>
                        <Table
                            size="middle"
                            columns={columns}
                            dataSource={approval}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <h4>Aktivitas Lokasi</h4>
                        <Table
                            size="middle"
                            columns={activityColumns}
                            dataSource={activityImage}
                            pagination={false}
                        />
                    </Col>
                    <Col span={1} />
                    <Col span={12}>
                        <h4>Biaya Perjalanan</h4>
                        <Table
                            size="middle"
                            columns={CostColumns}
                            dataSource={costTransportation}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <br />
                        <h4>Laporan</h4>
                        <Table
                            size="middle"
                            columns={reportColumns}
                            dataSource={report}
                            pagination={false}
                        />
            </div>
        );
}


export default SettlementDetail;