
export function getStatusApproval(record) {
    let statusValue = "Menunggu"; // Default status adalah "Menunggu"
    let statusColor = "blue"; // Default warna adalah "blue"
    if (record.approval != null) {
        let approvals = record.approval;
        if (approvals.length > 0) {
            // Mengecek jika semua status_approval_id adalah 2
            const allApproved = approvals.every(approval => approval.status_approval_id === 2);

            // Mengecek jika ada satu status_approval_id yang bernilai 1
            const hasPending = approvals.some(approval => approval.status_approval_id === 1);

            // Mengecek jika ada satu status_approval_id yang bernilai 3
            const hasRejected = approvals.some(approval => approval.status_approval_id === 3);

            if (allApproved) {
                // Jika semua status_approval_id adalah 2, statusnya adalah "Disetujui"
                statusValue = "Disetujui";
                statusColor = "green"; // Warna "green" untuk status "Disetujui"
            } else if (hasRejected) {
                // Jika ada satu status_approval_id yang bernilai 3, statusnya adalah "Ditolak"
                statusValue = "Ditolak";
                statusColor = "red"; // Warna "red" untuk status "Ditolak"
            } else if (hasPending) {
                // Jika ada satu status_approval_id yang bernilai 1, statusnya adalah "Menunggu"
                statusValue = "Menunggu";
            }
        }
        return { color: statusColor, value: statusValue };
    }
    return { color: statusColor, value: statusValue };
}