
import { Alert, Button, Col, Form, Input, Modal, Row, Table, TimePicker } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';


function Overtime() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Lembur';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode User',
            dataIndex: 'users_code',
            key: "users_code",
            render: (text, record) => {
                return record.users.users_code;
            }
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                return record.users.name;
            }
        },
        {
            title: 'Keterangan',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Jam Mulai Lembur',
            dataIndex: 'before_shift_overtime_duration',
            key: "before_shift_overtime_duration",
        },
        {
            title: 'Jam Akhir Lembur',
            dataIndex: 'after_shift_overtime_duration',
            key: "after_shift_overtime_duration",
        },
        {
            title: 'Jam Mulai Istirahat',
            dataIndex: 'before_shift_rest_duration',
            key: "before_shift_rest_duration",
        },
        {
            title: 'Jam Akhir Istirahat',
            dataIndex: 'after_shift_rest_duration',
            key: "after_shift_rest_duration",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                switch (record.status) {
                    case 'Approved':
                        return <b style={{ color: "#2E8B57" }}>{record.status}</b>;
                    case 'Rejected':
                        return <b style={{ color: "#FF4500" }}>{record.status}</b>;
                    default:
                        return <b style={{ color: "#00CED1" }}>{record.status}</b>;
                }
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (text, record) => {
                if (record.updated_at !== "")
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                if (record.status !== 'Process' && record.status !== "") {
                    return '';
                } else {
                    return (
                        <>
                            <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('agree', record)}>
                                <b style={{ marginLeft: 5 }}>Setujui</b>
                            </Button>
                            <Button type="primary" danger onClick={() => openModal('disagree', record)}>
                                <b style={{ marginLeft: 5 }}>Tolak</b>
                            </Button>
                        </>
                    );
                }
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/overtime?search=' + search + '&page=' + page.pagination.current, 'overtime');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setData(res.data.data);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.total_data
                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const openModal = async (type, data) => {
        if (type === 'agree') {
            Modal.confirm({
                title: 'NOTICE',
                content: 'Apakah anda ingin setujui data ini?',
                onOk: () => postApi('PUT', '/overtimeapproval/' + data.overtime_id, { status: 'Approved' }),
            });
        } else {
            Modal.confirm({
                title: 'NOTICE',
                content: 'Apakah anda ingin menolak data ini?',
                onOk: () => postApi('PUT', '/overtimeapproval/' + data.overtime_id, { status: 'Rejected' }),
            });
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }


    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Data Lembur</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                    <br />
                    <br />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </div>
    );
}


export default Overtime;