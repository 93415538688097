
import { Alert, Button, Checkbox, Input, Modal, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_MENU } from '../../Redux/actions/userActions';
import moment from 'moment';


function RoleUser() {
    const token = useSelector((state) => state.user.token);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [menu, setMenu] = useState([]);
    const [userRoleId, setUserRoleId] = useState();
    const [valueName, setValueName] = useState();
    const [valueMenu, setValueMenu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Akses';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (text, record) => {
                if (record.updated_at !== "")
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/userrole?page=' + page.pagination.current, 'userrole');
        getApi("GET", '/menu', 'menu');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                if (type === 'userrole') {
                    setData(res.data.data);
                    setPage({
                        ...page,
                        pagination: {
                            ...page.pagination,
                            total: res.data.total_data
                        }
                    });
                    setLoading(false);
                } else if (type === 'menu') {
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].status = false;
                        for (let i2 = 0; i2 < res.data[i].submenus.length; i2++) {
                            res.data[i].submenus[i2].status = false;
                        }
                    }
                    setMenu(res.data);
                } else if (type === "userrolebyid") {
                    var data = res.data;
                    var m = JSON.parse(JSON.stringify(menu));
                    for (let i = 0; i < m.length; i++) {
                        m[i].status = false;
                        for (let i2 = 0; i2 < m[i].submenus.length; i2++) {
                            m[i].submenus[i2].status = false;
                        }
                    }
                    setValueName(data.name);
                    var menus = [];
                    for (let i = 0; i < data.role_menu.length; i++) {
                        var item = data.role_menu[i];
                        var indexMenu = m.findIndex(o => Number(o.menu_id) === Number(item.menu_id));
                        if (indexMenu !== -1) {
                            var arr = {
                                menu_id: item.menu_id,
                                submenu: []
                            }
                            for (let i2 = 0; i2 < item.role_submenu.length; i2++) {
                                var item2 = item.role_submenu[i2];
                                arr.submenu.push({ submenu_id: item2.submenu_id });
                                if (m[indexMenu].submenus.length > 0) {
                                    for (let i3 = 0; i3 < m[indexMenu].submenus.length; i3++) {
                                        if (m[indexMenu].submenus[i3].submenu_id === item2.submenu_id) {
                                            m[indexMenu].submenus[i3].status = true;
                                        }
                                    }
                                }
                            }
                            m[indexMenu].status = true;
                            menus.push(arr);
                        }
                    }
                    setUserRoleId(data.user_role_id);
                    setMenu(m);
                    setValueMenu(menus);
                    setdataModal(data)
                    setIsModal(true);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values, status) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: status !== 'get_data_user' ? values : null,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                if (status === 'get_data_user') {
                    var menu = res.data.user_role;
                    if (values.position === menu.position) {
                        menu.user_role_menus.sort((a, b) => (a.menu.sequence > b.menu.sequence) ? 1 : ((b.menu.sequence > a.menu.sequence) ? -1 : 0));
                        dispatch(
                            UPDATE_MENU(
                                JSON.stringify(menu),
                            )
                        )
                    }
                }
                // else if (status === 'put_user_role') {
                //     postApi('POST', '/login/token', values, 'get_data_user');
                // }
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', 'userrole/' + v.user_role_id, null, 'del_user_role'),
        });
    }

    const openModal = (type, data) => {
        var m = JSON.parse(JSON.stringify(menu));
        for (let i = 0; i < m.length; i++) {
            m[i].status = false;
            for (let i2 = 0; i2 < m[i].submenus.length; i2++) {
                m[i].submenus[i2].status = false;
            }
        }
        setMenu(m);
        if (type === 'edit') {
            getApi("GET", '/userrole/' + data.user_role_id, 'userrolebyid');
        } else {
            setIsModal(true);
        }
    }

    const onFinish = () => {
        var data = {
            "name": valueName,
            "menu": valueMenu,
        }
        if (dataModal != null) {
            postApi("PUT", 'userrole/' + userRoleId, data, 'put_user_role');
        } else {
            postApi("POST", 'userrole', data, 'post_user_role');
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const onChangeMenu = (item, index, type) => {
        var menus = JSON.parse(JSON.stringify(menu));
        var cek_menu = undefined;
        var objIndex = undefined;
        if (valueMenu.length > 0) {
            cek_menu = valueMenu.find(o => Number(o.menu_id) === Number(item.menu_id));
            objIndex = valueMenu.findIndex(o => Number(o.menu_id) === Number(item.menu_id));
        }
        var row = {
            submenu: []
        }

        var valMenus;
        //menu
        if (type === 'menu') {
            if (cek_menu === undefined) {
                row.menu_id = item.menu_id
                menus[index].status = true;
                if (item.submenus.length > 0) {
                    for (let i = 0; i < item.submenus.length; i++) {
                        row.submenu.push({ submenu_id: item.submenus[i].submenu_id });
                        menus[index].submenus[i].status = true;
                    }
                }
                var arr = [...valueMenu, row]
                setValueMenu(arr);
            } else {
                menus[index].status = !menus[index].status;
                for (let i = 0; i < item.submenus.length; i++) {
                    menus[index].submenus[i].status = !menus[index].submenus[i].status;
                }
                valMenus = valueMenu.filter(obj => obj.menu_id !== item.menu_id);
                setValueMenu(valMenus);
            }
        }

        //submenu
        if (type === 'submenu') {
            row.menu_id = item.menu_id;
            menus[index].status = true;
            var objSubIndex;
            if (cek_menu !== undefined) {
                objSubIndex = menus[index].submenus.findIndex(o => Number(o.submenu_id) === Number(item.submenu_id));
                row.submenu = valueMenu[objIndex].submenu;
                if (!item.status === true) {
                    row.submenu.push({ submenu_id: item.submenu_id });
                } else {
                    var index_submenu = valueMenu[objIndex].submenu.findIndex(o => Number(o.submenu_id) === Number(item.submenu_id));
                    row.submenu.splice(index_submenu, 1);
                }
                menus[index].submenus[objSubIndex].status = !item.status;
                if (row.submenu.length > 0) {
                    valueMenu[objIndex] = row;
                    setValueMenu(valueMenu);
                } else {
                    menus[index].status = false;
                    valMenus = valueMenu.filter(obj => obj.menu_id !== item.menu_id);
                    setValueMenu(valMenus);
                }
            } else {
                row.submenu.push({ submenu_id: item.submenu_id });
                objSubIndex = menus[index].submenus.findIndex(o => Number(o.submenu_id) === Number(item.submenu_id));
                menus[index].submenus[objSubIndex].status = true;
                var arr2 = [...valueMenu, row]
                setValueMenu(arr2);
            }
        }
        setMenu(menus);
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Hak Akses</h3>
            <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                <PlusOutlined />
                <b style={{ marginLeft: 5 }}>Tambah Akses</b>
            </Button>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Data Akses" open={isModal} onOk={onFinish} onCancel={() => closeModal()} okText="Simpan" >
                <p>Nama</p>
                <Input value={valueName} onChange={(e) => setValueName(e.target.value)} />
                <p>Menu</p>
                {
                    menu.length > 0 &&
                    menu.map((key, i) => (
                        <div key={i} >
                            <Checkbox checked={key.status} onChange={() => onChangeMenu(key, i, 'menu')}>
                                <p style={{ fontSize: 12 }}>{key.menu}</p>
                            </Checkbox>
                            {
                                key.submenus.length > 0 &&
                                key.submenus.map((key2, i2) => (
                                    <div key={i2} style={{ marginLeft: 25 }}>
                                        <Checkbox key={i} checked={key2.status} onChange={() => onChangeMenu(key2, i, 'submenu')}>
                                            <p style={{ fontSize: 11 }}>{key2.submenu}</p>
                                        </Checkbox>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </Modal>
        </div>
    );
}


export default RoleUser;