
import { Alert, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Steps, Table, Tag, TimePicker, TreeSelect } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { BarsOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useNavigate } from 'react-router-dom';
import { getStatusApproval } from '../../Utils/approval';
const { SHOW_PARENT } = TreeSelect;
const { Step } = Steps;


function SubmissionTravel() {
    const navigate = useNavigate();
    const selector = useSelector((state) => state.user);
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);
    const [userData, setUserData] = useState([]);
    const [userSelected, setUserSelected] = useState([]);
    const [transportion, setTransportion] = useState([]);
    const [transport, setTransport] = useState([]);
    const [formData, setFormData] = useState({});
    const [currentStep, setCurrentStep] = useState(0);

    const [cost, setCost] = useState({ total_cost: 0, total_join_visit: 0, pocket_money: 0, grand_total: 0 });

    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);


    const [linkFields, setLinkFields] = useState([{ name: '', label: '' }]);
    const [userFields, setUserFields] = useState([{ name: '', label: '' }]);
    // //Parameter
    const titlePage = 'Data Pengajuan Perjalanan Dinas';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode',
            dataIndex: 'budget_submission_code',
            key: "budget_submission_code",
        },
        {
            title: 'Judul',
            dataIndex: 'title',
            key: "title",
        },
        {
            title: 'Total Budget',
            dataIndex: 'grand_total',
            key: "grand_total",
            render: (text, record) => {
                return currencyFormat(record.grand_total);
            }
        },
        {
            title: 'Perihal',
            dataIndex: 'status_pay',
            key: "status_pay",
            render: (text, record) => {
                return <Tag>{record.status_pay}</Tag>;
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                return (<Tag color={getStatusApproval(record).color}>{getStatusApproval(record).value}</Tag>);
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/detailperjalanandinas', { state: { item: record } })}>
                            <BarsOutlined />
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    const columnTransport = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { return index + 1 },
        },
        {
            title: 'Tanggal',
            dataIndex: 'date_required',
            key: "date_required",
        },
        {
            title: 'Lokasi',
            dataIndex: 'location',
            key: "location",
        },
        {
            title: 'Tujuan',
            dataIndex: 'destination',
            key: "destination",
        },
        {
            title: 'Jarak Tempuh',
            dataIndex: 'distance',
            key: "distance",
        },
        {
            title: 'Waktu Tempuh',
            dataIndex: 'time',
            key: "time",
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button type="primary" danger onClick={() => deleteTransport(index)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    // User
    const columnsUser = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                return record.name;
            }
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: "level",
            render: (text, record) => {
                return record.level_detail.name;
            }
        },
        {
            title: 'Jabatan',
            dataIndex: 'position',
            key: "position",
            render: (text, record) => {
                return record.position.name;
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'division',
            key: "division",
            render: (text, record) => {
                return record.division.name;
            }
        },
        {
            title: 'Transport',
            dataIndex: 'transport',
            key: "transport",
            render: (text, record) => {
                var item = record.level_detail.benefit.benefit_transportation;
                var text = "";
                for (let i = 0; i < item.length; i++) {
                    text += item[i].transportation.jenis + " " + item[i].transportation.name + ", "
                }
                return text;
            }
        },
        {
            title: 'Biaya Penginapan',
            dataIndex: 'lodging_price',
            key: "lodging_price",
            render: (text, record) => {
                return currencyFormat(record.level_detail.benefit.lodging_price);
            }
        },
        {
            title: 'Join Visit',
            dataIndex: 'lodging_price',
            key: "lodging_price",
            render: (text, record) => {
                return currencyFormat(record.level_detail.benefit.join_visit_price);
            }
        },
        {
            title: 'Uang Saku',
            dataIndex: 'pocket_money',
            key: "pocket_money",
            render: (text, record) => {
                return currencyFormat(record.level_detail.benefit.pockey_money[0].budget);
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button type="primary" danger onClick={() => deleteUsers(index)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];


    useEffect(() => {
        var users = JSON.parse(selector.user);
        getApi("GET", '/requesttravel?search=' + search + '&page=' + page.pagination.current, 'request');
        getApi("GET", '/usertransportation/' + users.users_id, 'transportation');
        getApi("GET", '/usertransport?nopage=true', 'users');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'request':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'users':
                        var row = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row.push({
                                value: res.data.data[i].users_id,
                                label: res.data.data[i].name + " | " + res.data.data[i].level_detail.name,
                            });
                        }
                        setUser(row);
                        setUserData(res.data.data);
                        setLoading(false);
                        break;
                    case 'transportation':
                        var row = [];
                        var items = res.data.level_detail.benefit.benefit_transportation;
                        for (let i = 0; i < items.length; i++) {
                            row.push({
                                value: items[i].transportation.transportation_id,
                                label: items[i].transportation.jenis,
                            });
                        }
                        setTransportion(row);
                        setLoading(false);
                        break;

                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setLoading(false);
                setIsAlert(true);
                setCurrentStep(0);
                setTextAlert(res.message);
                closeModal();
                form.resetFields();
                setTransport([]);
                setUserSelected([]);
                setCost({ total_cost: 0, total_join_visit: 0, pocket_money: 0, grand_total: 0 });
                setLinkFields([{ name: '', label: '' }])
                setUserFields([{ name: '', label: '' }])
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/requesttravel/' + v.budget_submission_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            form.setFieldsValue({
                name: data.name,
                desc: data.desc,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = () => {
        var last_form = form.getFieldsValue();
        var cost_data = {
            "cost_lodging": cost.total_cost,
            "cost_join_visit": cost.total_join_visit,
            "cost_pocket_money": cost.pocket_money,
            "cost_transport": 0,
            "total_cost": cost.grand_total,
        }
        var ev = { ...formData, ...last_form };

        if (transport.length > 0) {
            var data = {
                "title": ev.name,
                "grand_total": ev.grand_total,
                "desc": ev.desc,
                "approval": [],
                "link": [],
                "transportation": transport,
                "user_submission": userSelected,
                ...cost_data
            }
            for (const key in ev) {
                if (ev.hasOwnProperty(key)) {
                    if (key.startsWith("approval-")) {
                        // const index = key.split("-")[1];
                        const value = ev[key];
                        data.approval.push(
                            {
                                "users_id": value,
                                "status_approval_id": 1
                            })
                    }
                    if (key.startsWith("link-")) {
                        // const index = key.split("-")[1];
                        const value = ev[key];
                        if (value !== undefined)
                            data.link.push(value);
                    }
                }
            }
            postApi("POST", '/requesttravel', data);
        } else {
            modalError('Masukan data transport terlebih dahulu')
        }
    }

    const closeModal = () => {
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const deleteTransport = (index) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                const newData = [...transport]; // Buat salinan data
                newData.splice(index, 1); // Hapus item pada index
                setTransport(newData); // Set state dengan data baru
            },
        });
    }

    const deleteUsers = (index) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                const newData = [...userSelected]; // Buat salinan data
                newData.splice(index, 1); // Hapus item pada index
                setUserSelected(newData); // Set state dengan data baru
                checkCostUser(newData);
            },
        });
    }
    // Linkn
    const handleAddLinkField = () => {
        setLinkFields([...linkFields, { value: '' }]);
    };

    const handleInputLinkChange = (index, key, value) => {
        const newFields = [...linkFields];
        newFields[index][key] = value;
        setLinkFields(newFields);
    };

    const handleDeleteLinkField = (index) => {
        const newFields = [...linkFields];
        newFields.splice(index, 1);
        setLinkFields(newFields);
    };

    // User
    const handleAddUserField = () => {
        setUserFields([...userFields, { name: '', label: '' }]);
    };

    const handleSelectUserChange = (index, value) => {
        const newFields = [...userFields];
        newFields[index].name = value;
        var f = form.getFieldValue('approval-0');
        setUserFields(newFields);
    };

    const handleDeleteUserField = (index) => {
        const newFields = [...userFields];
        newFields.splice(index, 1);
        setUserFields(newFields);
    };

    const addTransport = () => {
        var f = form.getFieldsValue();
        if (f.transportation_id != null) {
            if (f.date_required != null) {
                if (f.location != null) {
                    if (f.destination != null) {
                        if (f.distance != null) {
                            if (f.time != null) {
                                var op = {
                                    "transportation_id": f.transportation_id,
                                    "location": f.location,
                                    "destination": f.destination,
                                    "distance": f.distance.toFixed(2),
                                    "time": f.time.format('HH:mm:ss'),
                                    "date_required": f.date_required.format('YYYY-MM-DD'),
                                }
                                var hsl = [...transport, op];
                                setTransport(hsl);
                                form.setFieldsValue({
                                    transportation_id: null,
                                    location: null,
                                    destination: null,
                                    distance: null,
                                    time: null,
                                })
                            } else {
                                modalError("Perkiraan Waktu Tempuh Terlebih dahulu")
                            }
                        } else {
                            modalError("Lokassi (km) Terlebih dahulu")
                        }
                    } else {
                        modalError("Tujuan Terlebih dahulu")
                    }
                } else {
                    modalError("Lokasi Terlebih dahulu")
                }
            } else {
                modalError("Tanggal Perjalanan Terlebih dahulu")
            }
        } else {
            modalError("Pilih Transport Terlebih dahulu")
        }
    }

    const nextStep = () => {
        form.validateFields().then(values => {
            setFormData({ ...formData, ...values });
            const next = currentStep + 1;
            if (next < steps.length) {
                setCurrentStep(next);
            }
        }).catch(info => {
            console.error('Validate Failed:', info);
        });
    };

    const prevStep = () => {
        const prev = currentStep - 1;
        if (prev >= 0) {
            setCurrentStep(prev);
        }
    };

    const addUserEmployee = () => {
        var form_users = form.getFieldsValue();
        let obj = userData.find(o => o.users_id === form_users.users_id);
        var selectUser = [...userSelected, obj];
        setUserSelected(selectUser);
        checkCostUser(selectUser);
    }

    const checkCostUser = (ev) => {
        if (ev.length > 0) {
            ev.sort((a, b) => b.level_detail.benefit.lodging_price - a.level_detail.benefit.lodging_price);
            var pocket_money = 0;
            var total_cost = 0;
            var total_join_visit = 0;

            var checkData = ev.length % 2;
            var index = 0;
            if (checkData === 1) {
                index = 1;
                total_cost = Number(ev[0].level_detail.benefit.lodging_price);
                total_join_visit = Number(ev[0].level_detail.benefit.join_visit_price);
            }

            //hitung biaya penginapan
            for (let i = index; i < ev.length; i = i + 2) {
                total_cost += Number(ev[i].level_detail.benefit.lodging_price);
                total_join_visit += Number(ev[i].level_detail.benefit.join_visit_price);
            }

            //hitung uang saku
            for (let i = 0; i < ev.length; i++) {
                pocket_money += ev[i].level_detail.benefit.pockey_money[0].budget;
            }

            var grand_total = total_cost + total_join_visit + pocket_money;
            var costs = {
                total_cost,
                total_join_visit,
                pocket_money,
                grand_total
            }
            setCost(costs);
        } else {
            var costs = {
                total_cost: 0,
                total_join_visit: 0,
                pocket_money: 0,
                grand_total: 0
            }
            setCost(costs);
        }
    }


    const steps = [
        // Step 1: Pengajuan
        {
            title: "Pengajuan",
            content: (
                <>
                    <Form.Item
                        name="name"
                        label="Judul Pengajuan Perjalanan Dinas"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Pengajuan Perjalanan Dinas kosong!'
                            },
                        ]}
                    >
                        <Input placeholder='judul perjalanan dinas' />
                    </Form.Item>
                    <Form.Item
                        name="desc"
                        label="Keterangan"
                        rules={[
                            {
                                required: true,
                                message: 'Keterangan kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea placeholder='Cth : perjalanan dinas tangerang ke jakarta' />
                    </Form.Item>
                    <Form.Item
                        name="grand_total"
                        label="Estimasi Total Biaya"
                        rules={[
                            {
                                required: true,
                                message: 'Estimasi Biaya kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Cth : Rp. 1.000.000' />
                    </Form.Item>
                </>
            )
        },
        // Step 2: Karyawan
        {
            title: "User",
            content: (
                <>
                    <br />
                    <Alert
                        message="Notice"
                        description="jika ada perbedaan level karyawan maka untuk biaya penginapan akan memakai level yang tertinggi dan ditambah join visit"
                        type="warning"
                        banner
                    />

                    <Row>
                        <Col span={12}>
                            <h3>User</h3>
                            <Form.Item
                                name="users_id"
                                label="Pilih User"
                                rules={[
                                    {
                                        required: false,
                                        message: 'User kosong!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih User"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={user}
                                />
                            </Form.Item>
                            <Button style={{ marginBottom: 10 }} type='primary' onClick={addUserEmployee} >Tambah User</Button>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <h3>Biaya yang akan dikeluarkan</h3>
                            <table>
                                <tr>
                                    <td>Biaya Karyawan</td>
                                    <td>:</td>
                                    <td>{currencyFormat(cost.total_cost)}</td>
                                </tr>
                                <tr>
                                    <td>Join Visit</td>
                                    <td>:</td>
                                    <td>{currencyFormat(cost.total_join_visit)}</td>
                                </tr>
                                <tr>
                                    <td>Uang Saku</td>
                                    <td>:</td>
                                    <td>{currencyFormat(cost.pocket_money)}</td>
                                </tr>
                                <tr>
                                    <td><b>Total Biaya</b></td>
                                    <td>:</td>
                                    <td><b>{currencyFormat(cost.grand_total)}</b></td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                    <Table
                        size="middle"
                        columns={columnsUser}
                        dataSource={userSelected}
                    />
                </>
            )
        },
        {
            title: "Transport",
            content: (
                <>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="transportation_id"
                                label={`Pilih Transport`}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Transport kosong!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih Transport"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={transportion}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item
                                name="date_required"
                                label="Tanggal Perjalanan"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Tanggal Perjalanan kosong!'
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    placeholder='Masukkan Tanggal Perjalanan' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="location"
                                label="Lokasi"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Lokasi kosong!'
                                    },
                                ]}
                            >
                                <Input placeholder='Cth : Jakarta' />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item
                                name="destination"
                                label="Tujuan"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Tujuan kosong!'
                                    },
                                ]}
                            >
                                <Input placeholder='Cth : Tangerang' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="distance"
                                label="Lokasi (km)"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Lokasi kosong!'
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder='Cth : 50' />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item
                                name="time"
                                label="Perkiraan Waktu Tempuh"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Perkiraan Waktu Tempuh kosong!'
                                    },
                                ]}
                            >
                                <TimePicker style={{ width: "100%" }} format="HH:mm" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={addTransport} >Tambahkan</Button>
                    <hr />
                    <Table
                        size="middle"
                        columns={columnTransport}
                        dataSource={transport}
                        loading={loading}
                    />
                    <br />
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={handleAddLinkField} >Tambah Link</Button>
                    {linkFields.map((field, index) => (
                        <Form.Item
                            name={`link-${index}`}
                            key={index}
                            label={`Link Tiket ${index + 1} (Opsional)`}
                        >
                            <Row>
                                <Col span={20}>
                                    <Input
                                        value={field.name}
                                        onChange={(e) => handleInputLinkChange(index, 'name', e.target.value)}
                                    />
                                </Col>
                                <Col span={1} />
                                <Col span={3}>
                                    <Button danger type='primary' onClick={() => handleDeleteLinkField(index)}>Delete</Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    ))}
                </>
            )
        },
        // Step 4: Approval By
        {
            title: "Approval",
            content: (
                <>
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={handleAddUserField} >Tambah User</Button>
                    {userFields.map((field, index) => (
                        <Row>
                            <Col span={20}>
                                <Form.Item
                                    name={`approval-${index}`}
                                    key={index}
                                    label={`Pilih User ${index + 1}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'User kosong!'
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Pilih User"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={user}
                                        value={field.name}
                                        onChange={(value) => handleSelectUserChange(index, value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1} />
                            <Col span={3}>
                                <div style={{ height: 30 }} />
                                <Button danger type='primary' onClick={() => handleDeleteUserField(index)}>Delete</Button>
                            </Col>
                        </Row>
                    ))}
                </>
            )
        },
    ];

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Pengajuan Perjalanan Dinas</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />

            <Modal
                width={1000}
                title="Pengajuan Perjalanan Dinas"
                open={isModal}
                onCancel={() => closeModal()}
                footer={[
                    <Button key="back" onClick={prevStep} disabled={currentStep === 0}>
                        Previous
                    </Button>,
                    currentStep < steps.length - 1 ? (
                        <Button key="next" type="primary" onClick={nextStep}>
                            Next
                        </Button>
                    ) : (
                        <Button key="submit" type="primary" onClick={onFinish}>
                            Submit
                        </Button>
                    ),
                ]}
            >
                <Steps style={{ marginTop: 10, marginBottom: 10 }} current={currentStep}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <Form form={form} layout="vertical" initialValues={formData}>
                    {steps[currentStep].content}
                </Form>
            </Modal>
        </div>
    );
}


export default SubmissionTravel;