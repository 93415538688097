
import { Alert, Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';


function LevelDetail() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [level, setLevel] = useState([]);
    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Level Detail';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Level Detail',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Level',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                return (<p>{record.level.name}</p>);
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (text, record) => {
                if (record.updated_at !== "")
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/leveldetail?search=' + search + '&page=' + page.pagination.current, 'level_detail');
        if (level.length === 0) {
            getApi('GET', '/level?nopage=true', 'level');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'level_detail':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;

                    case 'level':
                        var level = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item1 = res.data.data[i];
                            level.push({
                                value: item1.level_id,
                                label: item1.name
                            });
                        }
                        setLevel(level);
                        break;
                    default:
                        break;
                }
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/leveldetail/' + v.level_detail_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                name: data.name,
                level_id: data.level_id,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            postApi("PUT", '/leveldetail/' + dataModal.level_detail_id, ev);
        } else {
            postApi("POST", '/leveldetail', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Level Detail</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Data Divisi" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label="Nama"
                        rules={[
                            {
                                required: true,
                                message: 'Nama kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="level_id"
                        label="Pilih Level"
                        rules={[
                            {
                                required: true,
                                message: 'Level Kosong!'
                            },
                        ]}
                    >
                        <Select
                            options={level}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default LevelDetail;