
import { Alert, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Steps, Table, Tag, TimePicker } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { BarsOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useNavigate } from 'react-router-dom';
import { getStatusApproval } from '../../Utils/approval';
const { Step } = Steps;


function SubmissionRegular() {
    const navigate = useNavigate();
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [product, setProduct] = useState([]);
    const [user, setUser] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [currentStep, setCurrentStep] = useState(0);

    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [total, setTotal] = useState(0);

    const [userFields, setUserFields] = useState([{ name: '', label: '' }]);

    // //Parameter
    const titlePage = 'Data Pengajuan Regular';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode',
            dataIndex: 'budget_submission_code',
            key: "budget_submission_code",
        },
        {
            title: 'Judul',
            dataIndex: 'title',
            key: "title",
        },
        {
            title: 'Total Budget',
            dataIndex: 'grand_total',
            key: "grand_total",
            render: (text, record) => {
                return currencyFormat(record.grand_total);
            }
        },
        {
            title: 'Perihal',
            dataIndex: 'status_pay',
            key: "status_pay",
            render: (text, record) => {
                return <Tag>{record.status_pay}</Tag>;
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (text, record) => {
                if (record.updated_at !== "")
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                return (<Tag color={getStatusApproval(record).color}>{getStatusApproval(record).value}</Tag>);
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/detailregular', { state: { item: record } })}>
                            <BarsOutlined />
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    const columnProduct = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { return index + 1 },
        },
        {
            title: 'Nama Produk',
            dataIndex: 'product_name',
            key: "product_name",
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: "price",
            render: (text, record) => {
                return currencyFormat(record.price);
            }
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: "qty",
        },
        {
            title: 'Total Harga',
            dataIndex: 'total',
            key: "total",
            render: (text, record) => {
                return currencyFormat(record.total);
            }
        },
        {
            title: 'Dibutuhkan Tgl',
            dataIndex: 'date_required',
            key: "date_required",
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button type="primary" danger onClick={() => deleteProduct(index)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/requestbudgetproduct?search=' + search + '&page=' + page.pagination.current, 'request');
        getApi("GET", '/users?nopage=true', 'users');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'request':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                    case 'users':
                        var row = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row.push({
                                value: res.data.data[i].users_id,
                                label: res.data.data[i].name,
                            });
                        }
                        setUser(row);
                        setLoading(false);
                        break;
                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/requestbudgetproduct/' + v.budget_submission_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            form.setFieldsValue({
                name: data.name,
                desc: data.desc,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = () => {
        var last_form = form.getFieldsValue();
        var ev = { ...formData, ...last_form };
        if (product.length > 0) {

            //hitung grand total
            var grandtotal = product.map(item => {
                const totalPrice = item.price * item.qty;
                return totalPrice;
            }).reduce((acc, curr) => acc + curr, 0);

            var data = {
                "title": ev.title,
                "grand_total": grandtotal,
                "desc": ev.desc,
                "approval": [],
                "budget_buy_product": product
            }
            for (const key in ev) {
                if (ev.hasOwnProperty(key)) {
                    if (key.startsWith("approval-")) {
                        const value = ev[key];
                        data.approval.push(
                            {
                                "users_id": value,
                                "status_approval_id": 1
                            })
                    }
                }
            }
            postApi("POST", '/requestbudgetproduct', data);
        } else {
            modalError('Masukan data transport terlebih dahulu')
        }
    }


    const closeModal = () => {
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };


    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    // User
    const handleAddUserField = () => {
        setUserFields([...userFields, { name: '', label: '' }]);
    };

    const handleSelectUserChange = (index, value) => {
        const newFields = [...userFields];
        newFields[index].name = value;
        var f = form.getFieldValue('approval-0');
        setUserFields(newFields);
    };

    const handleDeleteUserField = (index) => {
        const newFields = [...userFields];
        newFields.splice(index, 1);
        setUserFields(newFields);
    };

    const addProduct = () => {
        var f = form.getFieldsValue();
        if (f.product_name != null) {
            if (f.price != null) {
                if (f.qty != null) {
                    if (f.date_required != null) {
                        var op = {
                            "title": f.title,
                            "date_required": f.date_required.format('YYYY-MM-DD'),
                            "product_name": f.product_name,
                            "qty": f.qty,
                            "price": f.price,
                            "total": f.total,
                            "desc": f.desc
                        }
                        var hsl = [...product, op];
                        setProduct(hsl);
                        form.setFieldsValue({
                            date_required: null,
                            product_name: null,
                            qty: null,
                            price: null,
                            total: null,
                        })
                    } else {
                        modalError("Masukkan Dibutuhkan Tanggal Terlebih dahulu")
                    }
                } else {
                    modalError("Masukkan Qty Terlebih dahulu")
                }
            } else {
                modalError("Masukkan Harga Terlebih dahulu")
            }
        } else {
            modalError("Masukkan Nama Produk Terlebih dahulu")
        }
    }

    const deleteProduct = (index) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                const newData = [...product]; // Buat salinan data
                newData.splice(index, 1); // Hapus item pada index
                setProduct(newData); // Set state dengan data baru
            },
        });
    }

    const handlePriceChange = (value) => {
        const qtyValue = form.getFieldValue('qty');
        const totalPrice = value * qtyValue;
        form.setFieldsValue({ total: totalPrice });
        setTotal(totalPrice);
    };

    const handleQtyChange = (value) => {
        const priceValue = form.getFieldValue('price');
        const totalPrice = priceValue * value;
        form.setFieldsValue({ total: totalPrice });
        setTotal(totalPrice);
    };

    const nextStep = () => {
        form.validateFields().then(values => {
            setFormData({ ...formData, ...values });
            const next = currentStep + 1;
            if (next < steps.length) {
                setCurrentStep(next);
            }
        }).catch(info => {
            console.error('Validate Failed:', info);
        });
    };

    const prevStep = () => {
        const prev = currentStep - 1;
        if (prev >= 0) {
            setCurrentStep(prev);
        }
    };

    const steps = [
        {
            title: "Pengajuan",
            content: (
                <>
                    <Form.Item
                        name="title"
                        label="Nama Pengajuan Regular"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Pengajuan Regular kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="desc"
                        label="Keterangan"
                        rules={[
                            {
                                required: false,
                                message: 'Keterangan kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </>
            )
        },
        {
            title: "Produk",
            content: (
                <>
                    <Form.Item
                        name="product_name"
                        label={`Nama Produk`}
                        rules={[
                            {
                                required: false,
                                message: 'Nama Produk kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="price"
                                label="Harga"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Harga kosong!'
                                    },
                                ]}
                            >
                                <InputNumber
                                    onChange={handlePriceChange}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    placeholder='Harga' />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item
                                name="qty"
                                label="Qty"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Qty kosong!'
                                    },
                                ]}
                            >
                                <InputNumber
                                    onChange={handleQtyChange}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Form.Item
                                name="total"
                                label="Total Harga"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Total Harga kosong!'
                                    },
                                ]}
                            >
                                <InputNumber
                                    disabled={true}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    placeholder='Total Harga' />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Form.Item
                                name="date_required"
                                label="Dibutuhkan Tanggal"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Tanggal kosong!'
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={addProduct} >Tambahkan</Button>
                    <hr />
                    <Table
                        size="middle"
                        columns={columnProduct}
                        dataSource={product}
                    />
                </>
            )
        },
        {
            title: "Approval",
            content: (
                <>
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={handleAddUserField} >Tambah User</Button>
                    {userFields.map((field, index) => (
                        <Row>
                            <Col span={20}>
                                <Form.Item
                                    name={`approval-${index}`}
                                    key={index}
                                    label={`Pilih User ${index + 1}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'User kosong!'
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Pilih User"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={user}
                                        value={field.name}
                                        onChange={(value) => handleSelectUserChange(index, value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1} />
                            <Col span={3}>
                                <div style={{ height: 30 }} />
                                <Button danger type='primary' onClick={() => handleDeleteUserField(index)}>Delete</Button>
                            </Col>
                        </Row>
                    ))}
                </>
            )
        }
    ]

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Pengajuan Regular</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />

            <Modal
                title="Data Pengajuan Regular"
                open={isModal}
                width={900}
                onCancel={() => closeModal()}
                footer={[
                    <Button key="back" onClick={prevStep} disabled={currentStep === 0}>
                        Previous
                    </Button>,
                    currentStep < steps.length - 1 ? (
                        <Button key="next" type="primary" onClick={nextStep}>
                            Next
                        </Button>
                    ) : (
                        <Button key="submit" type="primary" onClick={onFinish}>
                            Submit
                        </Button>
                    ),
                ]}
            >
                <Steps style={{ marginTop: 10, marginBottom: 10 }} current={currentStep}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <Form form={form} layout="vertical" initialValues={formData}>
                    {steps[currentStep].content}
                </Form>
            </Modal>
        </div>
    );
}


export default SubmissionRegular;