
import { Col, Row,Table, Tag } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useLocation, useNavigate } from 'react-router-dom';
import {getStatusApproval} from '../../Utils/approval';


function SubmissionRegularDetail() {
    const token = useSelector((state) => state.user.token);
    const location = useLocation();
    const navigate = useNavigate();
    const [product, setProduct] = useState([]);
    const [data, setData] = useState(null);
    const [benefit, setBenefit] = useState(null);
    const [approval, setApproval] = useState([]);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });


    // //Parameter
    const titlePage = 'Data Pengajuan Perjalanan Dinas';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                return record.users.name;
            }
        },
        {
            title: 'Jabatan',
            dataIndex: 'position',
            key: "position",
            render: (text, record) => {
                return record.users.position.name;
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'division',
            key: "division",
            render: (text, record) => {
                return record.users.division.name;
            }
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                var statusColor;
                if (record.status_approval.status_approval_id === 1) {
                    statusColor = 'blue';
                } else if (record.status_approval.status_approval_id === 2) {
                    statusColor = 'green';
                } else {
                    statusColor = 'red';
                }
                return (<Tag color={statusColor}>{record.status_approval.status}</Tag>);
            }
        },
    ];

    const productColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Produk',
            dataIndex: 'product_name',
            key: "product_name",
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Harga',
            dataIndex: 'price',
            key: "price",
            render: (text, record) => {
                return currencyFormat(record.price);
            }
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: "qty",
        },
        {
            title: 'Total Harga',
            dataIndex: 'total',
            key: "total",
            render: (text, record) => {
                return currencyFormat(record.total);
            }
        },
    ];
    useEffect(() => {
        if (location.state != null) {
            getApi("GET", '/requestbudgetproduct/' + location.state.item.budget_submission_id, 'request');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page)]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'request':
                        getApi("GET", '/benefitlevel?leveldetail=' + res.data.user.level_detail_id, 'benefit');
                        setData(res.data);
                        setApproval(res.data.approval)
                        setProduct(res.data.budget_buy_product)
                        setLoading(false);
                        break;
                    case 'benefit':
                        setBenefit(res.data.data[0]);
                        setLoading(false);
                        break;
                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }



    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const checkStatus = () => {
        return (<Tag color={getStatusApproval(data).color}>{getStatusApproval(data).value}</Tag>);
    }

    if (data != null && benefit != null)
        return (
            <div>
                <Helmet>
                    <title>{titlePage}</title>
                </Helmet>
                <h1>{data.budget_submission_code}</h1>
                <h3>Judul : {data.title}</h3>
                {checkStatus()}
                <Row>
                    <Col span={12}>
                        <h4>Detail</h4>
                        <Row>
                            <Col>
                                <div>Deskripsi </div>
                                <div>Estimasi Budget </div>
                                <div>Realisasi </div>
                            </Col>
                            <Col>
                                <div>: {data.desc}</div>
                                <div>: {currencyFormat(data.grand_total)}</div>
                                <div>: {data.realisasi > 0 ? currencyFormat(data.realisasi) : 'Belum direalisasikan'}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <h4>Yang Mengajukan</h4>
                        <Row>
                            <Col>
                                <div>Nama </div>
                                <div>Posisi </div>
                                <div>Divisi </div>
                            </Col>
                            <Col>
                                <div>: {data.user.name}</div>
                                <div>: {data.user.position.name}</div>
                                <div>: {data.user.division.name}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <h4>Approval By</h4>
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={approval}
                    pagination={page.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
                <h4>Daftar Produk</h4>
                <Table
                    size="middle"
                    columns={productColumns}
                    dataSource={product}
                    loading={loading}
                />
            </div>
        );
}


export default SubmissionRegularDetail;