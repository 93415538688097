import React from 'react';
import { OrgDiagram } from 'basicprimitivesreact';
import { PageFitMode, Enabled } from 'basicprimitives';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { Row, Select } from 'antd';
import { useSelector } from 'react-redux';

const photos = 'data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxMOEhAQEBIREA8QDxAPEBAQDRAREBMQFRUWGBUSFRUYHSggGBolGxUTITEhJSkrLi4uFx8zODMsNygtLis'+
'BCgoKDQ0NDg0NDisZFRkrLS0rKy0tKy03Ky03Ky0rNysrKysrKysrNysrKystNzcrKysrKysrNysrKysrKysrK//AABEIAOAA4QMBIgACEQEDEQH/xAAbAAEAAgMBAQAAAAAAAAAAAAAABgcBBAUDAv/EAEEQ'+
'AAIBAgIFCQUFBgYDAAAAAAABAgMRBDEFBhIhURMiMkFhcYGRoRQjQlKxYpKiwdFDcoOy4fAHU2OCk/EkMzT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDE'+
'QA/ALpAAAAAAAAAAAA52kNN0MPuqVEpfJHnT8lkB0QQ/Ga7rKjSb+1UnZeEY3v5o5FfWrEz+NQXCEEvrd+oFjgqitpOtPpVqj/iNfSxrutJ5yk++cv1At8FQcrL5pffke1HSFaHRq1F/Ek/qBbIK1w+s2Jhb3'+
'm2uE4xd/HM6uE13kt1Wkmvmpys/uvd6gTUHJ0frFh69lGajJ/DPmu/BX3M6wAAAAAAAAAAAZAAGAAAAAAA+K1WMIuU2oxim3JuySXWB9nH0xrFSwt4tudX/Lha6/eeUSO6d1slUvTw94U8nUynLuXwr17iL2/'+
'v8wOxpTWSviLra5KD+Cm2t3bLN+nccZK2W4yAAAAAAAAAAAAw0dPRmnK+GsoTcoL9nNuUfDh4HNAFh6H1ppYi0J+6qP4ZPmt5c2X5M75TjV8zv6D1nqYa0Kl6tHLe+fBdj6+5gWIDwweLhXgqlOSlGWTXqnwf'+
'Ye4AAAAABkAAYAAAA869aNOMpzajCKcpSeSSzYHzjMVGjCVSo1GMVdt/TvK509pyeLl1wpRfMp3/ABS4v6GNP6aljJ33xpR6EH/NL7X0OUQAAUAAAAAAAAAAAAAAAAAABu6I0pUwk9unvT6dNvmzXbwfaWTor'+
'SUMVTVSm/3ovOMuDKpN3Q+k54SoqkN6e6cL2Uo/r2gWqDXwGMhiKcatN3jJX7U+tPtT3GwAAAGQABgAACB65aZ5WfIU37um+e18VRZLujv8e4kWtWlvZqVou1WreFPivmn4J+bRW6QAAAAAAAAA6GjNEVMR0V'+
'aF983l/U2dX9D+0PbnupRe/wC0+BNacFFKMUkkrJLKwHGwmrNKHTvUfbuXkjow0ZRjlSh4wT+ptADWlo+k/wBlT/44mpiNAUJ/BsvjF2OoAIbpPVqdJOVN8pFdVrSX6nBZaBHNZtDqSdamrSW+aS6S494ESAA'+
'AAAAAB2tVtMey1NmT9zUaU7/C7bp/RPs7iyEynSe6laV5WnyE37yjFbN3vlTyT8MvICSgADIAAwAcvWbHez4epNO02uTg1mpS3X8N78AIJrHpD2mvOad4Q93T4bMXva73d+XA5hiKskluSVkuC4GQAAAAAAem'+
'HpOpKMFnKSivFnmdLV1f+RS7/wAgJzhMOqUIwjlFW/qeoAAAAAAADVwAK+05g+QrTj8L50e5/wBs0CSa6RW1SfW4tepGwAAAAAAbWi8c8NVhVXwPnLjB9JeXqlwNUAXBTmpJSW9SSafFPI+iPakYzlMOoPOjL'+
'k+6OcV4J28CQgZAAGCFf4g4q8qNFZJSqy73zYfSp6E1Ky1qr8pi63CDjTXgldedwOUAAAAAAAAbmh6mzXpP/UivN2NM9MM3twtntxt33QFmAwjIAAAAAAAAEP1ynerCPCF/NkfOzrZf2h3y2I27rfrc4wAAAA'+
'AAAAEi1GxWxiHTfRqwf34716bfkiwCqND1+Tr0J8Kkb7r7m7P0Za4GQABgqLF1dupVl81ao/xNL0SLdKaou6T47/MD7AAAAAAAANvRNuWpXy5SP1NQ+oSs01mmn5AWcDxwVdVacJr4opnsAAAAAAAA3beBD9c'+
'rcrDjye/zdiPm7pjFctWnPqvaP7q3I0gAAAAAAAAMOWzzlnHnLvW9Fv4ee1GEuMYvzRUE1ufcy2NESvQw740KT/AgNwAAYKapKyS4bvJ2LlKixlLYq1YfLVqesm16NAeQAAAAAAAAAAlup2LvGdJ/C9qPc8/U'+
'kZBdWMRsV4p5TTh55E6AAAAAABzNY8RydCbW5ytBeOfpc6ZGdc8Rup0+tvafd1ARUAAAAAAAAAAYlk+5lsaIjahQXChSX4EVRsbXNy2ub57i3sPDZjCPCMV5ID1AAGCtNbKHJ4qrwns1F4re/NPyLLIX/iDhd'+
'9GssudSl/NB/wA68QIiAAAAAAAAAAPqnNxaks0013osTRmMVenGa61ZrhLrRXJIdTsS1UlT6px2vFf9gS8AAAAB81JqKcm7JK7fYV7pfG8vVlPqvaK+ysiUa313Gior9pKz7lvsQsAAAAAAAAAAANzQ+H5WvQ'+
'hxqxb7o85/QtYr/UXC7eIlUfRpU39+e5eil5osADIAAwc3WLA+0YepTW+VtqC+3Hel45eJ0gBTkXdJ8UmZOprNo/2bETilaFS9Wnws3zku5/VcTlgAAAAAAAADsap//Qv3J/kciMb7lvbySzJXqtomdOTq1Fs'+
'3jsxi89/WwJIAAAAAjuui93Sf239CIk+0/gXiKTjHpJqUe23UQfE4WdJ2nFxfavoB4gAAAAAAAAG3onAvE1adJZSleXZBb5Py3eKAm+pOD5PDqbzrN1P9uUfRJ+JIDEI2SSySsu4yBkAAYAAHH1o0T7VRaj/7'+
'Yc+m+3rj4r8itfNPrTVmi4iDa6aF5OXtFNcyb96kujPql3P695BFgDKRRgHa0dq5Uq2c/dx7VzvIkuB0LRo2tHal80t7AiGC0PVrdGLUfmluR3MJqpFWdWbl9mKsvMkgA1sLgKdHoQjF8bb/ADNkAAAAAAAHz'+
'VpqatJKSfU1dH0AOJjdWqVTfC9N9m+PkzhY7V2tS3xXKR4xz8icACsJRadmmmup7mYLHxmAp1lz4p9tt/mRzSOq7jeVGW0vkln4PrAjYPurSlBuMk4yWaasz4AE/wBTNFcjT5aatUqpNXW+NPc0uy+fkR3VXQ'+
'3tNTbmvc03eX2pbmofm/6lipAAABkAAYAAA+KtNTi4yScZJxaaumnmmfYArjTmr08PUUYJzp1JWpy4N/DJ8e3rO/ofQkMOlKVpVeuTyXcSeUb7madajs5ZEHkACgAAAAAAAAAAAAAAAAAANTSGjoYiNprf1SX'+
'SXcyK09W6sq/Iro9J1bc1Q49/YTinTcn2cTdpwUcgPLA4SNCEacFaMVZfm32nuAAAAGQABgAAAAAMNGQBrVcP1ryNdqx0T4qUlL9esDQB7Tw7WW/6nlYDAAAAAAAAAAAAHrCg32LtA8j3pUG89y9T2p0VHtfE'+
'9QMRjbIyAAAAAAAZAAGAAAAAAAAAAAPmcE81c+gBrywy6nb1PN4d9j8TcAGg6UuDPnZfA6IA52y+B9Km31M3wBpxw7fBeJ6Rwq63f0NgAfEKaWSPsAAAAAAAAAAAAMgAD//Z';


function Organize() {
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  const [division, setDivision] = useState([]);
  const [data, setData] = useState([]);
  const [config, setConfig] = useState({
    pageFitMode: PageFitMode.AutoSize,
    autoSizeMinimum: { width: 100, height: 100 },
    cursorItem: 0,
    highlightItem: 0,
    items: []
  });


  useEffect(() => {
    getApi("GET", '/division?nopage=true', 'division');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApi = (method, url, type) => {
    setLoading(true);
    const dataReq = {
      method: method,
      url: url,
      data: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    };
    apiCall(dataReq).then((res) => {
      if (res.status === "success") {
        switch (type) {
          case 'users':
            var row = [];
            for (let i = 0; i < res.data.data.length; i++) {
              var item = res.data.data[i];
              row.push({
                id: item.users_id,
                parent: item.users_parent_id,
                title: item.name,
                description: item.position.name,
                image: item.image !== "" ? item.image : photos
              });
            }
            config.items = row;
            setConfig(config);
            setLoading(false);
            break;
          case 'division':
            var row = [];
            for (let i = 0; i < res.data.data.length; i++) {
              row.push({
                value: res.data.data[i].division_id,
                label: res.data.data[i].name,
              });
            }
            setDivision(row);
            setLoading(false);
            break;
          default:
            break;
        }
      } else {
        setLoading(false);
      }
    }).catch((e) => {
      setLoading(false);
      console.log(e.message);
    })
  }

  const onSelected = (ev) => {
    getApi("GET", '/users?nopage=true&division=' + ev, 'users');
  }

  return (
    <div className="App">
      <h3>Struktur Organisasi</h3>
      <Row>
        <Select
          showSearch
          style={{width:300,textAlign:'left'}}
          placeholder="Pilih Divisi"
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={division}
          onSelect={(ev) => onSelected(ev)}
        />
      </Row>
      <div style={{textAlign:"center"}}>
      <OrgDiagram centerOnCursor={true} config={config} />
      </div>
    </div>
  );
}

export default Organize;
