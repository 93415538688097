import { HomeOutlined, ClockCircleOutlined, BookOutlined,PoundOutlined, InboxOutlined, FileTextOutlined, GatewayOutlined, FileDoneOutlined,FileOutlined, TeamOutlined, SettingOutlined, AppstoreOutlined, FormOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function MenuSideBars() {

    const selector = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [item, setItem] = useState();

    useEffect(() => {
        var user_role = JSON.parse(selector.menu);
        var menu = [];
        if (user_role !== null) {
            for (let i = 0; i < user_role.role_menu.length; i++) {
                var mn = user_role.role_menu[i];
                var sub_mn = [];
                if (mn.role_submenu.length) {
                    for (let i2 = 0; i2 < mn.role_submenu.length; i2++) {
                        var sub = mn.role_submenu[i2];
                        if (sub.user_role_submenu_ !== null) {
                            sub_mn.push(getItem(sub.submenu.submenu, sub.submenu.link));
                        }
                    }
                }
                if (sub_mn.length > 0)
                    menu.push(getItem(mn.menu.menu, mn.menu.link, getIcon(mn.menu.icon), sub_mn));
                else
                    menu.push(getItem(mn.menu.menu, mn.menu.link, getIcon(mn.menu.icon)));

            }
        }
        setItem(menu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getIcon = (icon_name) => {
        switch (icon_name) {
            case 'home':
                return <HomeOutlined />;
            case 'form':
                return <FormOutlined />
            case 'paper':
                return <FileOutlined />
            case 'approval':
                return <FileDoneOutlined />;
            case 'report':
                return <FileTextOutlined />;
            case 'master':
                return <AppstoreOutlined />;
            case 'book':
                return <BookOutlined />;
            case 'team':
                return <TeamOutlined />;
            case 'settings':
                return <SettingOutlined />;
            case 'membership':
                return <TeamOutlined />
            case 'penjualan':
                return <InboxOutlined />;
            case 'meeting':
                return <GatewayOutlined />;
            case 'benefit':
                return <PoundOutlined />
            case 'absen':
                return <ClockCircleOutlined />
        }
    }

    return (
        <Sider
            width={200}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={['dashboard']}
                // selectedKeys={['pos']}
                // defaultOpenKeys={['sub1']}
                style={{
                    height: '100%',
                    borderRight: 0,
                    fontSize: 11
                }}
                items={item}
                onClick={({ key }) => navigate('/' + key)}
            />
        </Sider>
    );
}

export default MenuSideBars;