
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './Redux/stores/store';
import Router from './Route/Router';

const App = () => {
  return (
    <>
      <HelmetProvider>
        <Provider store={store}>
          <Router />
        </Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
