
import { Col, Row, Table, Tag } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useLocation } from 'react-router-dom';
import { getStatusApproval } from '../../Utils/approval';


function SubmissionTravelDetail() {
    const token = useSelector((state) => state.user.token);
    const location = useLocation();
    const [data, setData] = useState(null);
    const [benefit, setBenefit] = useState(null);
    const [approval, setApproval] = useState([]);
    const [transportation, setTransportation] = useState([]);

    const [loading, setLoading] = useState(false);

    // //Parameter
    const titlePage = 'Data Pengajuan Perjalanan Dinas';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                if (record.users != null)
                    return record.users.name;
                else
                    return ""
            }
        },
        {
            title: 'Jabatan',
            dataIndex: 'position',
            key: "position",
            render: (text, record) => {
                if (record.users != null)
                    return record.users.position.name;
                else
                    return ""
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'division',
            key: "division",
            render: (text, record) => {
                if (record.users != null)
                    return record.users.division.name;
                else
                    return ""
            }
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                var statusColor;
                if (record.status_approval.status_approval_id === 1) {
                    statusColor = 'blue';
                } else if (record.status_approval.status_approval_id === 2) {
                    statusColor = 'green';
                } else {
                    statusColor = 'red';
                }
                return (<Tag color={statusColor}>{record.status_approval.status}</Tag>);
            }
        },
    ];

    const DestinationColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Transportasi',
            dataIndex: 'transportation',
            key: "transportation",
            render: (text, record) => {
                return record.transportation.name;
            }
        },
        {
            title: 'Lokasi',
            dataIndex: 'location',
            key: "location",
        },
        {
            title: 'Tujuan',
            dataIndex: 'destination',
            key: "destination",
        },
        {
            title: 'Jarak Tempuh (km)',
            dataIndex: 'distance',
            key: "distance",
        },
        {
            title: 'Waktu Tempuh',
            dataIndex: 'time',
            key: "time",
        },
    ];
    useEffect(() => {
        if (location.state != null) {
            getApi("GET", '/requesttravel/' + location.state.item.budget_submission_id, 'request');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'request':
                        getApi("GET", '/benefitlevel?leveldetail=' + res.data.user.level_detail_id, 'benefit');
                        setData(res.data);
                        setApproval(res.data.approval);
                        setTransportation(res.data.transportation);
                        setLoading(false);
                        break;
                    case 'benefit':
                        setBenefit(res.data.data[0]);
                        setLoading(false);
                        break;
                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }


    const checkStatus = () => {
        return (<Tag color={getStatusApproval(data).color}>{getStatusApproval(data).value}</Tag>);
    }

    if (data != null && benefit != null)
        return (
            <div>
                <Helmet>
                    <title>{titlePage}</title>
                </Helmet>
                <h1>{data.budget_submission_code}</h1>
                <h3>Judul : {data.title}</h3>
                {checkStatus()}

                <h4>Yang Mengajukan</h4>
                <Row>
                    <Col>
                        <div>Nama </div>
                        <div>Posisi </div>
                        <div>Divisi </div>
                    </Col>
                    <Col>
                        <div>: {data.user.name}</div>
                        <div>: {data.user.position.name}</div>
                        <div>: {data.user.division.name}</div>
                    </Col>
                </Row>
                <h4>Akomodasi Yang Didapat</h4>
                {
                    benefit.benefit_transportation.map((key, i) => {
                        if (key.transportation.name !== "") {
                            return (<Tag key={i}>{key.transportation.jenis + " - " + key.transportation.name}</Tag>);
                        } else {
                            return (<Tag key={i}>{key.transportation.jenis}</Tag>);
                        }
                    })
                }
                <hr />
                <h3>Detail</h3>
                <Row>
                    <Col span={12}>
                        <h4>Informasi</h4>
                        <Row>
                            <Col>
                                <div>Deskripsi </div>
                                <div>Estimasi Budget </div>
                                <div>Realisasi </div>
                            </Col>
                            <Col>
                                <div>: {data.desc}</div>
                                <div>: {currencyFormat(data.grand_total)}</div>
                                <div>: {data.realisasi > 0 ? currencyFormat(data.realisasi) : 'Belum direalisasikan'}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <h4>Approval By</h4>
                        {
                            approval.length > 0 &&
                            <Table
                                size="middle"
                                columns={columns}
                                dataSource={approval}
                                pagination={false}
                            />
                        }
                    </Col>
                </Row>
                <br />
                <h4>Destinasi</h4>
                <Table
                    size="middle"
                    columns={DestinationColumns}
                    dataSource={transportation}
                    pagination={false}
                />
            </div>
        );
}


export default SubmissionTravelDetail;