
import { Alert, Button, Card, Col, Form, Image, Input, InputNumber, Modal, Row, Select, Steps, Table, Tag, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { BarsOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useNavigate } from 'react-router-dom';
import { getStatusApproval } from '../../Utils/approval';
const { Step } = Steps;


function Settlement() {
    const navigate = useNavigate();
    const token = useSelector((state) => state.user.token);
    const [imageUrl, setImageUrl] = useState();

    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [currentStep, setCurrentStep] = useState(0);;
    const [user, setUser] = useState([]);

    const [userFields, setUserFields] = useState([{ name: '', label: '' }]);
    // list
    const [report, setReport] = useState([]);
    const [perdin, setPerdin] = useState([]);
    const [activityImage, setActivityImage] = useState([]);
    const [costTransportation, setCostTransportation] = useState([]);

    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Settlement';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Judul',
            dataIndex: 'title',
            key: "title",
        },
        {
            title: 'Deskripsi',
            dataIndex: 'desc',
            key: "desc",
        },
        {
            title: 'Jumlah Approval',
            dataIndex: 'approval',
            key: "approval",
            render: (text, record) => {
                return record.approval.length;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                return (<Tag color={getStatusApproval(record).color}>{getStatusApproval(record).value}</Tag>);
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/detailsettlement', { state: { item: record } })}>
                            <BarsOutlined />
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    const columnTransportation = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { return index + 1 },
        },
        {
            title: 'Bukti Foto',
            dataIndex: 'image',
            key: "image",
            render: (text, record) => {
                if (record.image !== undefined && record.image !== null && record.iamge !== "") {
                    return <Image width={50} height={50} src={record.image} />;
                } else {
                    return <p>tidak ada gambar</p>
                }
            }
        },
        {
            title: 'Nama Biaya',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'Biaya',
            dataIndex: 'cost',
            key: "cost",
            render: (text, record) => {
                return currencyFormat(record.cost);
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button type="primary" danger onClick={() => deleteTransport(index)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    const columnActivity = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { return index + 1 },
        },
        {
            title: 'Bukti Foto',
            dataIndex: 'image',
            key: "image",
            render: (text, record) => {
                if (record.image !== undefined && record.image !== null && record.iamge !== "") {
                    return <Image width={50} height={50} src={record.image} />;
                } else {
                    return <p>tidak ada gambar</p>
                }
            }
        },
        {
            title: 'Nama Toko',
            dataIndex: 'store_name',
            key: "store_name",
        },
        {
            title: 'Nama Kota',
            dataIndex: 'city',
            key: "city",
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <>
                        <Button type="primary" danger onClick={() => deleteActivity(index)}>
                            <DeleteOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/settlement?search=' + search + '&page=' + page.pagination.current, 'request');
        getApi("GET", '/requesttravel?nopage=true', 'perdin');
        getApi("GET", '/users?nopage=true', 'users');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'request':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'perdin':
                        var row = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row.push({
                                value: res.data.data[i].budget_submission_id,
                                label: res.data.data[i].budget_submission_code + " | " + res.data.data[i].title + " | " + getStatusApproval(res.data.data[i]).value,
                            });
                        }
                        setPerdin(row);
                        setLoading(false);
                        break;
                    case 'users':
                        var row2 = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row2.push({
                                value: res.data.data[i].users_id,
                                label: res.data.data[i].name,
                            });
                        }
                        setUser(row2);
                        setLoading(false);
                        break;
                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/settlement/' + v.settlement_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            form.setFieldsValue({
                name: data.name,
                desc: data.desc,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = () => {
        var last_form = form.getFieldsValue();
        var ev = { ...formData, ...last_form };
        var data = {
            budget_submission_id: formData.budget_submission_id,
            title: formData.title,
            desc: formData.desc,
            report_settlement: report,
            approval: [],
            activity_image: activityImage,
            cost_transportation: costTransportation
        }
        for (const key in ev) {
            if (ev.hasOwnProperty(key)) {
                if (key.startsWith("approval-")) {
                    const value = ev[key];
                    data.approval.push(
                        {
                            "users_id": value,
                            "status_approval_id": 1
                        })
                }
            }
        }
        postApi("POST", '/settlement', data);
    }

    const closeModal = () => {
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const nextStep = () => {
        form.validateFields().then(values => {
            setFormData({ ...formData, ...values });
            const next = currentStep + 1;
            if (next < steps.length) {
                setCurrentStep(next);
            }
        }).catch(info => {
            console.error('Validate Failed:', info);
        });
    };

    const prevStep = () => {
        const prev = currentStep - 1;
        if (prev >= 0) {
            setCurrentStep(prev);
        }
    };

    const deleteTransport = (index) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                const newData = [...costTransportation]; // Buat salinan data
                newData.splice(index, 1); // Hapus item pada index
                setCostTransportation(newData); // Set state dengan data baru
            },
        });
    }

    const deleteActivity = (index) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                const newData = [...activityImage]; // Buat salinan data
                newData.splice(index, 1); // Hapus item pada index
                setActivityImage(newData); // Set state dengan data baru
            },
        });
    }

    const deleteReport = (index) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => {
                const newData = [...report]; // Buat salinan data
                newData.splice(index, 1); // Hapus item pada index
                setReport(newData); // Set state dengan data baru
            },
        });
    }

    const addReport = () => {
        var f = form.getFieldsValue();
        if (f.city != null) {
            if (f.address != null) {
                if (f.pic != null) {
                    if (f.store_type != null) {
                        if (f.consumer_traffic != null) {
                            if (f.store_background != null) {
                                if (f.potency != null) {
                                    if (f.target_realization != null) {
                                        if (f.visitation_update != null) {
                                            var op = f
                                            var hsl = [...report, op];
                                            setReport(hsl);
                                            form.resetFields();
                                        } else {
                                            modalError("Masukkan Update Visitasi Terlebih dahulu")
                                        }
                                    } else {
                                        modalError("Masukkan Target Realization Terlebih dahulu")
                                    }
                                } else {
                                    modalError("Masukkan Potensi Terlebih dahulu")
                                }
                            } else {
                                modalError("Masukkan Latar Belakang Toko Terlebih dahulu")
                            }
                        } else {
                            modalError("Masukkan Traffic Consumen Terlebih dahulu")
                        }
                    } else {
                        modalError("Masukkan Tipe Toko Terlebih dahulu")
                    }
                } else {
                    modalError("Masukkan PIC Terlebih dahulu")
                }
            } else {
                modalError("Masukkan Alamat Terlebih dahulu")
            }
        } else {
            modalError("Masukkan Nama Kota Terlebih dahulu")
        }
    }

    const addCost = () => {
        var f = form.getFieldsValue();
        if (f.name != null) {
            if (f.cost != null) {
                if (imageUrl != null) {
                    f.image = imageUrl;
                    var op = f
                    var hsl = [...costTransportation, op];
                    setCostTransportation(hsl);
                    form.resetFields();
                    setImageUrl(null)
                } else {
                    modalError("Masukkan Upload Bukti Foto Terlebih dahulu")
                }
            } else {
                modalError("Masukkan Biaya (Rp.) Terlebih dahulu")
            }
        } else {
            modalError("Masukkan Nama Biaya Terlebih dahulu")
        }
    }

    const addAcitivty = () => {
        if (f.name != null) {
            if (f.cost != null) {
                if (imageUrl != null) {
                    var f = form.getFieldsValue();
                    f.image = imageUrl;
                    var op = f
                    var hsl = [...activityImage, op];
                    setActivityImage(hsl);
                    form.resetFields();
                    setImageUrl(null)
                } else {
                    modalError("Masukkan Upload Bukti Foto Terlebih dahulu")
                }
            } else {
                modalError("Masukkan Nama Kota Terlebih dahulu")
            }
        } else {
            modalError("Masukkan Nama Toko Terlebih dahulu")
        }
    }


    const uploadImage = (info) => {
        getBase64(info.file, (url) => {
            setImageUrl(url);
        });
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleAddUserField = () => {
        setUserFields([...userFields, { name: '', label: '' }]);
    };

    const handleSelectUserChange = (index, value) => {
        const newFields = [...userFields];
        newFields[index].name = value;
        var f = form.getFieldValue('approval-0');
        setUserFields(newFields);
    };

    const handleDeleteUserField = (index) => {
        const newFields = [...userFields];
        newFields.splice(index, 1);
        setUserFields(newFields);
    };

    const steps = [
        {
            title: "Pengajuan",
            content: (
                <>
                    <Form.Item
                        name="budget_submission_id"
                        label="Pilih Pengajuan Perdin"
                        rules={[
                            {
                                required: true,
                                message: 'Pengajuan Perdin kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Pengajuan Perdin"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={perdin}
                        />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label="Judul Settlement"
                        rules={[
                            {
                                required: true,
                                message: 'Judul Settlement kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="desc"
                        label="Keterangan"
                        rules={[
                            {
                                required: false,
                                message: 'Keterangan kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </>
            )
        },
        {
            title: "Biaya Perdin",
            content: (
                <>
                    <Form.Item
                        name="name"
                        label="Nama Biaya"
                        rules={[
                            {
                                required: false,
                                message: 'Nama Biaya kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="cost"
                        label="Biaya (Rp.)"
                        rules={[
                            {
                                required: false,
                                message: 'Biaya kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Potensi' />
                    </Form.Item>

                    <p>Upload Bukti Foto</p>
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        onChange={uploadImage}
                        beforeUpload={() => false}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ height: 100, width: 100, borderRadius: 5 }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>

                    <br />
                    <br />
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={addCost} >Tambah</Button>
                    <Table
                        columns={columnTransportation}
                        dataSource={costTransportation}

                    />
                </>
            )
        },
        {
            title: "Report",
            content: (
                <>
                    <Row>
                        <Col span={11}>
                            <h4>Form Report</h4>
                            <Form.Item
                                name="city"
                                label="Nama Kota"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Nama Kota kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="Alamat"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Alamat kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="pic"
                                label="PIC"
                                rules={[
                                    {
                                        required: false,
                                        message: 'PIC kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="store_type"
                                label="Tipe Toko"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Tipe Toko kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="consumer_traffic"
                                label="Traffic Consumen"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Traffic Consumen kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="store_background"
                                label="Latar Belakang Toko"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Latar Belakang Toko kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="potency"
                                label="Potensi"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Potensi kosong!'
                                    },
                                ]}
                            >
                                <InputNumber
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '100%' }}
                                    placeholder='Potensi' />
                            </Form.Item>
                            <Form.Item
                                name="target_realization"
                                label="Target Realization"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Target Realization kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="visitation_update"
                                label="Update Visitasi"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Update Visitasi kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Button style={{ marginBottom: 10 }} type='primary' onClick={addReport} >Tambah</Button>
                        </Col>
                        <Col span={1} />
                        <Col span={12}>
                            <h4>Daftar Report</h4>
                            {report.map((key, index) => (
                                <Card style={{ marginBottom: 10 }} key={index} >
                                    <Row>
                                        <Col span={20}>
                                            PIC : <b>{key.pic}</b><br />
                                            Alamat : {key.city} - {key.address} <br />
                                            Potensi : {currencyFormat(key.potency)} <br />
                                            Update Visitasi : {key.visitation_update} <br />
                                            Traffic Consumen : {key.consumer_traffic}
                                        </Col>
                                        <Col span={4} style={{ textAlign: "right" }}>
                                            <Tag color='orange'>{key.store_type}</Tag>
                                            <br /><br />
                                            <Button type="primary" danger onClick={() => deleteReport(index)}>
                                                <DeleteOutlined />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            ))}
                        </Col>
                    </Row>
                </>
            )
        },
        {
            title: "Aktivitas",
            content: (
                <>
                    <Form.Item
                        name="store_name"
                        label="Nama Toko"
                        rules={[
                            {
                                required: false,
                                message: 'Nama Toko kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="city"
                        label="Nama Kota"
                        rules={[
                            {
                                required: false,
                                message: 'Nama Kota kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <p>Upload Bukti Foto</p>
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        onChange={uploadImage}
                        beforeUpload={() => false}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ height: 100, width: 100, borderRadius: 5 }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>

                    <br />
                    <br />
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={addAcitivty} >Tambah</Button>
                    <Table
                        columns={columnActivity}
                        dataSource={activityImage}

                    />
                </>
            )
        },
        {
            title: "Approval",
            content: (
                <>
                    <Button style={{ marginBottom: 10 }} type='primary' onClick={handleAddUserField} >Tambah User</Button>
                    {userFields.map((field, index) => (
                        <Row>
                            <Col span={20}>
                                <Form.Item
                                    name={`approval-${index}`}
                                    key={index}
                                    label={`Pilih User ${index + 1}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'User kosong!'
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Pilih User"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={user}
                                        value={field.name}
                                        onChange={(value) => handleSelectUserChange(index, value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={1} />
                            <Col span={3}>
                                <div style={{ height: 30 }} />
                                <Button danger type='primary' onClick={() => handleDeleteUserField(index)}>Delete</Button>
                            </Col>
                        </Row>
                    ))}
                </>
            )
        },
    ]

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Settlement</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />

            <Modal
                title="Data Settlement"
                width={1000}
                open={isModal}
                onCancel={() => closeModal()}
                footer={[
                    <Button key="back" onClick={prevStep} disabled={currentStep === 0}>
                        Previous
                    </Button>,
                    currentStep < steps.length - 1 ? (
                        <Button key="next" type="primary" onClick={nextStep}>
                            Next
                        </Button>
                    ) : (
                        <Button key="submit" type="primary" onClick={onFinish}>
                            Submit
                        </Button>
                    ),
                ]}
            >
                <Steps style={{ marginTop: 10, marginBottom: 10 }} current={currentStep}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <Form form={form} layout="vertical" initialValues={formData}>
                    {steps[currentStep].content}
                </Form>
            </Modal>
        </div>
    );
}


export default Settlement;