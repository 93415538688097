
import { Alert, Card, Col, Divider, Row, Statistic, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { TeamOutlined, InboxOutlined, IdcardOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


function Dashboard() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const [totalPengajuan, setTotalPengajuan] = useState(0);
    const [totalProduct, setTotalProduct] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [meeting, setMeeting] = useState();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [search, setSearch] = useState('');
    const [load, setLoad] = useState(0);
    const [dataLine, setDataLine] = useState({
        labels: [],
        datasets: [
            {
                label: 'Kemarin',
                data: [2, 3, 4, 6, 2, 8, 3, 7, 4, 4],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Hari ini',
                data: [9, 6, 3, 6, 3, 7, 2, 4, 7, 3],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    });

    const options = {
        responsive: true,
        plugins: {
            // legend: {
            //   position: 'top',
            // },
            title: {
                display: true,
                text: 'Perjalanan Dinas Hari ini',
            },
        },
    };

    const titlePage = 'Dashboard';
    const meetingColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                return record.users.name
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'divisi',
            key: "divisi",
        },
        {
            title: 'Waktu Awal',
            dataIndex: 'time_start',
            key: "time_start",
        },
        {
            title: 'Waktu Akhir',
            dataIndex: 'time_end',
            key: "time_end",
        },
    ];

    useEffect(() => {
        const currentDate = moment().format('YYYY-MM-DD');
        getApi("GET", '/dashboard', 'card');
        getApi("GET", '/meeting?page=1&date=' + currentDate, 'meeting');
        // getApi('GET', '/historyuser?search=' + search + '&page=' + page.pagination.current, 'history');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlert, load]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case "card":
                        var item = res.data;
                        setTotalPengajuan(item.total_pengajuan_dinas);
                        setTotalProduct(item.total_pengajuan_produk);
                        setTotalUser(item.total_user);
                        setLoading(false);
                        break;
                    case "meeting":
                        var item = res.data.data;
                        setMeeting(item);
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const searchData = (value) => {
        setSearch(value);
        setLoad(load + 1);
    }
    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Dashboard</h3>
            <Row>
                <Col span={8}>
                    <Card style={{width:'80%',borderRadius:20,margin:0,padding:0, background: 'linear-gradient(45deg, #4099ff, #73b4ff)'}}>
                        <div style={{fontSize:14,color:"white",fontWeight:"bold"}}>Total Pengajuan Perjalanan Dinas</div>
                        <div style={{fontSize:25,fontWeight:"bold",color:"white"}}>{totalPengajuan}</div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{width:'80%',borderRadius:20,margin:0,padding:0,background: 'linear-gradient(45deg,#2ed8b6,#59e0c5)'}}>
                        <div style={{fontSize:14,color:"white",fontWeight:"bold"}}>Total Pengajuan Produk</div>
                        <div style={{fontSize:25,fontWeight:"bold",color:"white"}}>{totalProduct}</div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{width:'80%',borderRadius:20,margin:0,padding:0, background: 'linear-gradient(45deg,#FFB64D,#ffcb80)'}}>
                        <div style={{fontSize:14,color:"white",fontWeight:"bold"}}>Total User</div>
                        <div style={{fontSize:25,fontWeight:"bold",color:"white"}}>{totalUser}</div>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col span={12}>
                    <h4>Jadwal Meeting Sekarang</h4>
                    <Table
                        size="small"
                        columns={meetingColumns}
                        dataSource={meeting}
                        loading={loading}
                        pagination={false}
                    />
                </Col>
                <Col span={12} >
                    <Line options={options} data={dataLine} />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;