
import { Alert, Button, Col, Form, Input, Modal, Row, Table, Tag } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';
import { useNavigate } from 'react-router-dom';


function Approval() {
    const navigate = useNavigate();
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [btn, setBtn] = useState({ color: "#04AA6D", text: 'Setujui', status: 2 });
    const [loading, setLoading] = useState(false);
    const [isModalBtn, setIsModalBtn] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Approval';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode',
            dataIndex: 'code',
            key: "code",
            render: (text, record) => {
                return (record.budget_submission.budget_submission_code);
            }
        },
        {
            title: 'Judul',
            dataIndex: 'title',
            key: "title",
            render: (text, record) => {
                return (record.budget_submission.title);
            }
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: "user",
            render: (text, record) => {
                return (record.budget_submission.user.name);
            }
        },
        {
            title: 'Nominal',
            dataIndex: 'price',
            key: "price",
            render: (text, record) => {
                return (currencyFormat(record.budget_submission.grand_total));
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: "status",
            render: (text, record) => {
                var statusValue;
                var statusColor;
                if (record.status_approval.status_approval_id === 1) {
                    statusValue = 'Sedang Menunggu';
                    statusColor = 'blue';
                } else if (record.status_approval.status_approval_id === 2) {
                    statusValue = 'Disetujui';
                    statusColor = 'green';
                } else {
                    statusValue = 'Ditolak';
                    statusColor = 'red';
                }
                return (<Tag color={statusColor}>{statusValue}</Tag>);
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="default" style={{ marginRight: 10 }} onClick={() => linkDetail(record)}>
                            <b style={{ marginLeft: 5 }}>Lihat</b>
                        </Button>
                        {
                            record.status_approval.status_approval_id == 1 &&
                            <>
                                <Button type="primary" style={{ marginRight: 10, backgroundColor: "#04AA6D" }} onClick={() => openModal('agree', record)}>
                                    <b style={{ marginLeft: 5 }}>Setujui</b>
                                </Button>
                                <Button type="primary" danger style={{ marginRight: 10 }} onClick={() => openModal('disagree', record)}>
                                    <b style={{ marginLeft: 5 }}>Tolak</b>
                                </Button>
                            </>
                        }
                    </>
                );
            },
        },
    ];

    const linkDetail = (record) => {
        switch (record.budget_submission.status_pay) {
            case "Pengajuan Perjalanan Dinas":
                navigate('/detailperjalanandinas', { state: { item: record } });
                break;
            case "Pengajuan Barang":
                navigate('/detailregular', { state: { item: record } });
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getApi("GET", '/approval?search=' + search + '&page=' + page.pagination.current, 'approval');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setData(res.data.data);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.total_data
                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/approval/' + v.approval_id, null),
        });
    }

    const openModal = async (type, data) => {
        switch (type) {
            case 'agree':
                setBtn({ color: "#04AA6D", text: 'Setujui', status: 2 })
                setdataModal(data)
                setIsModalBtn(true);
                break;
            case 'disagree':
                setBtn({ color: "#f44336", text: 'Tolak', status: 3 })
                setdataModal(data)
                setIsModalBtn(true);
                break;

            default:
                break;
        }
    }

    const onFinish = (ev) => {
        var data = {
            "status_approval_id": btn.status,
            "desc": ev.desc
        }
        postApi("PUT", '/approval/' + dataModal.approval_id, data);
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModalBtn(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Approval Pengajuan Perjalanan Dinas</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                </Col>
            </Row>
            <br />
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Pengajuan" open={isModalBtn} onOk={form.submit} onCancel={() => closeModal()} okText={btn.text} okButtonProps={{ style: { backgroundColor: btn.color } }}  >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="desc"
                        label="Keterangan"
                        rules={[
                            {
                                required: false,
                                message: 'Keterangan kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default Approval;