import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, List, Popover, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TOKEN, UPDATE_USER } from '../../../../Redux/actions/userActions';

function Headers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const s = useSelector((state) => state.user.user);
    const user = JSON.parse(s);

    const menu = [
        {
            name: "Profile",
            link: '/profile',
        },
        {
            name: "Rekening",
            link: '/bill',
        },
        {
            name: "Ubah Password",
            link: '/changepassword',
        },
        {
            name: "Log Out",
            link: '/logout',
        },
    ]

    //parameter
    // const user = JSON.parse(getUser("user"));

    //Menu Profile
    const content = (
        <div>
            <List
                dataSource={menu}
                renderItem={(item) => <List.Item style={{ border: 0 }}>
                    <Button onClick={() => {
                        if (item.link === '/logout') {
                            logout();
                        } else {
                            navigate(item.link);
                        }
                    }} type='link' style={{ color: item.link === '/logout' ? "#FF0000" : "#333" }}>{item.name}</Button>
                </List.Item>}
            />
        </div>
    );

    //Function
    const logout = () => {
        dispatch(UPDATE_TOKEN());
        dispatch(UPDATE_USER());
        navigate('/login');
    }


    return (
        <Header style={{ backgroundColor:'#4682B4' }} className="admin-header">
            <Row>
                <Col span={16} >
                    <h2 style={{ margin: 0, color: "#FFF" }}>Bolde Access</h2>
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        type="link"
                        style={{ color: "#FFF" }}
                        onClick={() => navigate('/profile')}>{user.name}</Button>
                    <Popover placement="bottom" content={content} trigger="click">
                        {
                            user.image !== "" ?
                                <Avatar src={user.image} style={{ border: "1px solid #F2F2F2" }} />
                                :
                                <Avatar icon={<UserOutlined />} />
                        }
                    </Popover>
                </Col>
            </Row>
        </Header>
    );
}

export default Headers;