
import { Alert, Button, Col, Form, Input, Modal, Row, Select, Table, Upload, message } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, EyeTwoTone, EyeInvisibleOutlined, UploadOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';


function Employee() {
    const token = useSelector((state) => state.user.token);
    //DATA
    const [data, setData] = useState([]);
    const [userRole, setUserRole] = useState([]);
    const [level, setLevel] = useState([]);
    const [area, setArea] = useState([]);
    const [position, setPosition] = useState([]);
    const [division, setDivison] = useState([]);
    const [user, setUser] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [form] = Form.useForm();
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Karyawan';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'NIK',
            dataIndex: 'nik',
            key: "nik",
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: "email",
        },
        {
            title: 'Jabatan',
            dataIndex: 'position',
            key: "position",
            render: (id, record) => {
                if (record.position !== null)
                    return record.position.name;
                else
                    return null;
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'divisi',
            key: "divisi",
            render: (id, record) => {
                if (record.division !== null)
                    return record.division.name;
                else
                    return null;
            }
        },
        {
            title: 'Level',
            dataIndex: 'level_detail',
            key: "level_detail",
            render: (id, record) => {
                if (record.level_detail !== null)
                    return record.level_detail.name;
                else
                    return null;
            }
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: "area",
            render: (id, record) => {
                if (record.area !== null)
                    return record.area.name;
                else
                    return null;
            }
        },
        {
            title: 'Akses',
            dataIndex: 'role',
            key: "role",
            render: (id, record) => {
                if (record.userrole !== null)
                    return record.userrole.name;
                else
                    return null;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/users?search=' + search + '&page=' + page.pagination.current, 'users');
        if (userRole.length === 0) {
            getApi('GET', '/userrole?nopage=true', 'userrole');
        }
        if (level.length === 0) {
            getApi('GET', '/leveldetail?nopage=true', 'level');
        }
        if (position.length === 0) {
            getApi('GET', '/position?nopage=true', 'position');
        }
        if (division.length === 0) {
            getApi('GET', '/division?nopage=true', 'division');
        }
        if (area.length === 0) {
            getApi('GET', '/area?nopage=true', 'area');
        }
        if (user.length === 0) {
            getApi('GET', '/users?nopage=true', 'user');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'users':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'userrole':
                        var u = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item = res.data.data[i];
                            u.push({
                                value: item.user_role_id,
                                label: item.name
                            });
                        }
                        setUserRole(u);
                        break;
                    case 'level':
                        var level = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item1 = res.data.data[i];
                            level.push({
                                value: item1.level_detail_id,
                                label: item1.name
                            });
                        }
                        setLevel(level);
                        break;
                    case 'position':
                        var position = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item2 = res.data.data[i];
                            position.push({
                                value: item2.position_id,
                                label: item2.name
                            });
                        }
                        setPosition(position);
                        break;
                    case 'division':
                        var division = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item3 = res.data.data[i];
                            division.push({
                                value: item3.division_id,
                                label: item3.name
                            });
                        }
                        setDivison(division);
                        break;
                    case 'area':
                        var area = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item4 = res.data.data[i];
                            area.push({
                                value: item4.area_id,
                                label: item4.name
                            });
                        }
                        setArea(area);
                        break;
                    case 'user':
                        var user = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item5 = res.data.data[i];
                            user.push({
                                value: item5.users_id,
                                label: item5.name
                            });
                        }
                        setUser(user);
                        break;

                    default:
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = (method, url, values) => {
        setLoading(true);
        setIsAlert(false);

        const form = new FormData();
        for (const name in values) {
            form.append(name, values[name]);
        }

        const dataReq = {
            method: method,
            url: url,
            data: {
                data: form,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/users/' + v.users_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                nik: data.nik,
                email: data.email,
                phone: data.phone,
                username: data.username,
                user_role_id: data.user_role_id,
                name: data.name,
                area_id: data.area_id,
                position_id: data.position_id,
                level_detail_id: data.level_detail_id,
                division_id: data.division_id
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            postApi('PUT', '/users/' + dataModal.users_id, ev);
        } else {
            postApi('POST', '/users', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const props = {
        name: 'file',
        action: 'http://localhost:8016/api/v1/upload/employee',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Karyawan</h3>
            <Row>
                <Col span={12}>
                    <Row>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Import Excel Talenta</Button>
                        </Upload>
                        <Search
                            placeholder="Pencarian"
                            allowClear
                            style={{marginLeft:10,width:'50%'}}
                            enterButton="Search"
                            size="medium"
                            onSearch={searchData}
                        />
                    </Row>
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Karyawan</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Data Karyawan" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form_users" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="user_role_id"
                        label="Pilih Posisi Hak Akses"
                        rules={[
                            {
                                required: true,
                                message: 'Posisi Hak Akses kosong!'
                            },
                        ]}
                    >
                        <Select
                            options={userRole}
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="Nama Lengkap"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Lengkap kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="nik"
                        label="NIK"
                        rules={[
                            {
                                required: true,
                                message: 'NIK kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Username kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {
                        dataModal == null &&
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Password kosong!'
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder="input password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    }
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                required: true,
                                message: 'E-mail kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Nomor HP"
                        rules={[
                            {
                                required: true,
                                message: 'Nomor HP kosong!'
                            },
                        ]}
                    >
                        <Input type='number' />
                    </Form.Item>
                    <Form.Item
                        name="position_id"
                        label="Pilih Jabatan"
                        rules={[
                            {
                                required: true,
                                message: 'Jabatan Kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Jabatan"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={position}
                        />
                    </Form.Item>
                    <Form.Item
                        name="level_detail_id"
                        label="Pilih Level"
                        rules={[
                            {
                                required: true,
                                message: 'Level Kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Level"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={level}
                        />
                    </Form.Item>
                    <Form.Item
                        name="division_id"
                        label="Pilih Divisi"
                        rules={[
                            {
                                required: true,
                                message: 'Divisi Kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Divisi"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={division}
                        />
                    </Form.Item>
                    <Form.Item
                        name="area_id"
                        label="Pilih Area"
                        rules={[
                            {
                                required: true,
                                message: 'Area Kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Area"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={area}
                        />
                    </Form.Item>
                    <Form.Item
                        name="users_parent_id"
                        label="Pilih Atasan"
                        rules={[
                            {
                                required: true,
                                message: 'Atasan Kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Atasan"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={user}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default Employee;