import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';
import { apiCall } from '../../API/RestApi';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER } from '../../Redux/actions/userActions';

function Profile() {
    const token = useSelector((state) => state.user.token);
    const users = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [file, setFile] = useState();
    const [user, setUser] = useState();
    const [countCall, setCountCall] = useState(0);

    useEffect(() => {
        callApi('GET', '/profile');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countCall]);

    const onFinish = (ev) => {
        if (imageUrl != null) {
            ev.image = imageUrl;
        }
        callApi('PUT', '/profile', ev);
    }

    const callApi = (method, url, values) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                if (method === 'PUT') {
                    var f = form.getFieldsValue();
                    modalSuccess(res.message);
                    setCountCall(countCall + 1);
                    var s = JSON.parse(users);
                    s.name = f.name;
                    s.username = f.username;
                    s.email = f.email;
                    s.phone = f.phone;
                    s.image = res.data.image
                    dispatch(UPDATE_USER(JSON.stringify(s)));
                } else {
                    var usr = res.data;
                    form.setFieldsValue({
                        username: usr.username,
                        email: usr.email,
                        phone: usr.phone,
                        name: usr.name,
                    })
                    setImageUrl(usr.image);
                }
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const uploadImage = (info) => {
        setFile(info.file);
        getBase64(info.file, (url) => {
            setImageUrl(url);
        });
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div>

            <Row>
                <Col span={12}>
                    <b>Profile</b>
                </Col>
                <Col span={12}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                        <Button type="primary" onClick={form.submit} >
                            <b style={{ marginLeft: 5 }}>Simpan</b>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                <Row>
                    <Col span={14}>

                        <Form.Item
                            name="name"
                            label="Nama Lengkap"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Lengkap kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Username kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    required: true,
                                    message: 'E-mail kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            label="Nomor HP"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nomor HP kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={8} >
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            onChange={uploadImage}
                            beforeUpload={() => false}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{ height: 140, width: 140 }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Col>
                </Row>
            </Form>
        </div>
    );

}

export default Profile;