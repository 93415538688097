import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function BenefitForm() {
    const token = useSelector((state) => state.user.token);
    const location = useLocation();
    const navigate = useNavigate();
    const [levelDetail, setLevelDetail] = useState();
    const [form] = Form.useForm();

    const train = [
        {
            "value": 3,
            "label": "Kelas Ekonom"
        },
        {
            "value": 4,
            "label": "Kelas Bisnis"
        },
        {
            "value": 5,
            "label": "Kelas Executive"
        },
    ]

    const plane = [
        {
            "value": 2,
            "label": "Kelas Ekonomi (Premium Airline)"
        },
        {
            "value": 1,
            "label": "Kelas Ekonomi (LCC)"
        },
    ]

    useEffect(() => {
        if (location.state != null) {
            const item = location.state.item;
            form.setFieldsValue({
                level_detail_id: item.level_detail_id,
                plane: item.benefit_transportation[0].transportation_id,
                train: item.benefit_transportation[1].transportation_id,
                pocket_1: item.pockey_money[0].budget,
                pocket_2: item.pockey_money[1].budget,
                pocket_3: item.pockey_money[2].budget,
                lodging_price: item.lodging_price,
                join_visit_price: item.join_visit_price,
                entertainment_price: item.entertainment_price
            })
        }
        getApi('/leveldetail?nopage=true', 'leveldetail');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (url, type) => {
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'leveldetail':
                        var row = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row.push({
                                value: res.data.data[i].level_detail_id,
                                label: res.data.data[i].name,
                            });
                        }
                        setLevelDetail(row);
                        break;
                }
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const save = async (ev) => {
        var pocket = [
            {
                "status": "Dalam Negeri",
                "budget": ev.pocket_1,
                "currency": "Rupiah"
            },
            {
                "status": "Luar Negeri (Asia)",
                "budget": ev.pocket_2,
                "currency": "USD"
            },
            {
                "status": "Luar Negeri (Non Asia)",
                "budget": ev.pocket_3,
                "currency": "USD"
            }
        ]

        var transportation = []
        transportation.push({ transportation_id: ev.plane });
        transportation.push({ transportation_id: ev.train });
        transportation.push({ transportation_id: 6 });
        transportation.push({ transportation_id: 7 });
        transportation.push({ transportation_id: 8 });
        ev.pocket_money = pocket;
        ev.transportation = transportation;
        delete ev.pocket_1;
        delete ev.pocket_2;
        delete ev.pocket_3;
        delete ev.plane;
        delete ev.train;
        if (location.state != null) {
            const item = location.state.item;
            postApi("PUT", '/benefitlevel/' + item.benefit_id, ev);
        } else {
            postApi("POST", '/benefitlevel', ev);
        }
    }

    const postApi = (method, url, values) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        apiCall(dataReq).then((res) => {
            console.log(res)
            if (res.status === "success") {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    return (
        <div>
            <div>
                {/* {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />} */}
                <h3>Form Benefit</h3>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" onClick={form.submit} size='large' >
                        <b style={{ marginLeft: 5 }}>Simpan</b>
                    </Button>
                </div>
                <Form form={form} name="form_store" layout="vertical" onFinish={save}>

                    <Form.Item
                        name="level_detail_id"
                        label="Pilih Level"
                        rules={[
                            {
                                required: true,
                                message: 'Level kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Level"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={levelDetail}
                        />
                    </Form.Item>

                    <h4>Transportasi</h4>
                    <Form.Item
                        name="plane"
                        label="Jenis Pesawat"
                        rules={[
                            {
                                required: true,
                                message: 'Jenis Pesawat kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Jenis Pesawat"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={plane}
                        />
                    </Form.Item>
                    <Form.Item
                        name="train"
                        label="Jenis Kereta"
                        rules={[
                            {
                                required: true,
                                message: 'Jenis Kereta kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Jenis Kereta"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={train}
                        />
                    </Form.Item>
                    <h4>Uang Saku</h4>
                    <Form.Item
                        name="pocket_1"
                        label="Dalam Negeri (RUPIAH)"
                        rules={[
                            {
                                required: true,
                                message: 'Dalam Negeri (RUPIAH) kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Dalam Negeri (RUPIAH)' />
                    </Form.Item>
                    <Form.Item
                        name="pocket_2"
                        label="Luar Negeri (Asia) (USD)"
                        rules={[
                            {
                                required: true,
                                message: 'Dalam Negeri (USD) kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Dalam Negeri (USD) kosong!' />
                    </Form.Item>
                    <Form.Item
                        name="pocket_3"
                        label="Luar Negeri (Non Asia) (USD)"
                        rules={[
                            {
                                required: true,
                                message: 'Dalam Negeri (Non USD) kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Dalam Negeri (USD)' />
                    </Form.Item>
                    <h4>Lainnya</h4>
                    <Form.Item
                        name="lodging_price"
                        label="Budget Penginapan"
                        rules={[
                            {
                                required: true,
                                message: 'Budget Penginapan kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Budget Penginapan' />
                    </Form.Item>
                    <Form.Item
                        name="join_visit_price"
                        label="Budget Join Visit / Gabung Penginapan"
                        rules={[
                            {
                                required: true,
                                message: 'Budget Join Visit / Gabung Penginapan kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Join Visit' />
                    </Form.Item>
                    <Form.Item
                        name="entertainment_price"
                        label="Budget Entertainment"
                        rules={[
                            {
                                required: true,
                                message: 'Budget Entertainment kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: '100%' }}
                            placeholder='Entertainment' />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default BenefitForm;