import React from 'react';
import { Result } from 'antd';

const NotFound = () => (
    <Result
        status="404"
        title="Sedang Diprosess..."
        subTitle="harap bersabar lagi dikerjakan ^^"
        // extra={<Button type="primary">Kenbali</Button>}
    />
);

export default NotFound;