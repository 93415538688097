import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useSelector } from 'react-redux';
import { apiCall } from '../../API/RestApi';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';

const ChangePassword = () => {
  const token = useSelector((state) => state.user.token);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (ev) => {
    setLoading(true);
    callApi('PUT', '/profile', ev);
  }

  const callApi = (method, url, values) => {
    const dataReq = {
      method: method,
      url: url,
      data: {
        data: values,
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
    };
    apiCall(dataReq).then((res) => {
      if (res.status === "success") {
        setLoading(false);
        form.resetFields();
        modalSuccess(res.message);
      } else {
        modalError(res.message);
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  return (
    <>
      <h4>Ubah Password</h4>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="currentPassword"
          label="Password Saat Ini"
          rules={[
            {
              required: true,
              message: 'Masukkan password saat ini!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="Password Baru"
          rules={[
            {
              required: true,
              message: 'Masukkan password baru!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Konfirmasi Password Baru"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Konfirmasi password baru!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Konfirmasi password tidak cocok!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePassword;
